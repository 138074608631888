import React from "react";
import { barchartlayout, totalchartconfig } from "../../logic/chartutils";
import Plot from 'react-plotly.js';
import { Paper } from "@material-ui/core";

const ChartByWeek = (props) => {
  let weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  let layout = { ...barchartlayout }
  let title = { ...barchartlayout.title }
  title.text = "P&L by Week"
  layout.title = title;
  let values = []
  let colors = []
  let greencolor = '#26a69a'
  let redcolor = '#ef5350'
  for (var id = 0; id < 7; id++) {
    values.push((props.data && props.data[id]) ? props.data[id] : 0)
    if (values[id] >= 0) colors.push(greencolor)
    else colors.push(redcolor);
  }
  return (
    <Paper className="jr-card-full  p-2">
      <div style={{ width: "100%", height: props.height }}>
        <Plot
          data={[
            {
              type: 'bar', x: weekdays, y: values, marker: {
                color: colors
              }
            },
          ]}
          layout={layout}
          style={{ width: "100%", height: "100%" }}
          config={totalchartconfig}
        />
      </div>
    </Paper>
  );
};

export default ChartByWeek;
