import { Paper } from "@material-ui/core";
import React from "react";

const StatsTable = ({ result }) => {
    return (
        <Paper className='p-2'>
            <table className='w-100'>
                <tbody>
                    <tr>
                        <td style={{ width: '65%' }}>Avg Trade</td>
                        <td>{result.avgTrade && result.avgTrade.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td style={{ width: '65%' }}>Ratio WL</td>
                        <td>{result.ratioWL && result.ratioWL.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td style={{ width: '65%' }}>CPC Ratio</td>
                        <td>{result.cpcRatio && result.cpcRatio.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td style={{ width: '65%' }}>Sharpe</td>
                        <td>{result.sharpe && result.sharpe.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td style={{ width: '65%' }}>SQN</td>
                        <td>{result.SQN && result.SQN.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td style={{ width: '65%' }}>PP Percentage</td>
                        <td>{result.perfectProfitPercentage && result.perfectProfitPercentage.toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </Paper>
    );
};

export default StatsTable;
