import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import { Modal, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Grid, Button, Container, Paper,TextField,Backdrop, CircularProgress, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';

import EmailCaptureContainer from '../../Components/EmailCaptureContainer';
import SimpleMsg from "../../Components/SimpleMsg";

import { GetJWTTokenString,getRequestSeasonAPI,validateEmail ,postRequestSeasonAPI} from '../../logic/common';

export default function SharpeRatioCalculator(props) {
  const { userid } = useParams();

  const [loading, setLoading] = useState(false);  
  const [emailCaptureOpen, setEmailCaptureOpen] = useState(false);
  const [msgboxOpen, setMsgboxOpen] = useState(false);

  const [annualReturn, setAnnualReturn] = useState(0);
  const [riskFreeRate, setRiskFreeRate] = useState(0);
  const [standardDeviation, setStandardDeviation] = useState(0);
  const [sharpeRatio, setSharpeRatio] = useState(0)


  useEffect(()=>{
    if(standardDeviation==0)
    {
      setSharpeRatio(0.0);
      
    }else{
      var sr = (annualReturn-riskFreeRate)/standardDeviation;
      var srFix = sr.toFixed(2);
      setSharpeRatio(srFix);

      const cb=(res)=>{
        var cnt =  res['data'][0]['result']['cnt'];
        var email =  res['data'][0]['result']['email'];
        if(parseInt(cnt)>15)
        {
            if(email=='')
            {
                setEmailCaptureOpen(true);
            }            
        }
      }
      const data = { userid: userid};
      postRequestSeasonAPI("usecount",data,cb);
    }
  },[annualReturn,riskFreeRate,standardDeviation])
  
  useEffect(() => {
    
  }, [])


  const handleClose = () => {
    setEmailCaptureOpen(false);
  }

  let totalPL = 0;
  const closeMsgbox=()=>
    {
        setMsgboxOpen(false);
    }
    const emailCallback=(res)=>
    {
        setLoading(false);
        setMsgboxOpen(true);
    }
    const onEmailContainerClose=()=>
    {
        setEmailCaptureOpen(false);
        setLoading(true);
    }
  return (
    <Container maxWidth='md'>
      <EmailCaptureContainer open={emailCaptureOpen} onClose={onEmailContainerClose} callback={emailCallback} userid={userid}></EmailCaptureContainer>
      <SimpleMsg open={msgboxOpen} handleClose={closeMsgbox}/>
      <Backdrop open={loading} style={{ zIndex: 1000 }}>
          <CircularProgress style={{ color: 'blue' }} /> <span style={{ color: 'blue' }}>&nbsp;&nbsp;<b>Loading...</b></span>
      </Backdrop>
      {
        <Grid container spacing={2}>
        <Grid item xs={12} className='text-left pt-4'>
          <Typography variant="h4" component="h1" gutterBottom>
            Sharpe Ratio Calculator
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <div>
            <div style={{paddingLeft:'30px'}}>
              <span style={{fontSize:'12pt'}}>Sharpe Ratio Formula</span>
                <ul style={{listStyle:'inside'}}>
                  <li>Sharpe Ratio= (Rp−Rf) / σp</li>
                </ul>
                <p><span style={{fontSize:'12pt'}}>Where:</span></p>
                <ul style={{listStyle:'inside'}}>
                  <li>Rp = Expected Portfolio Return</li>
                  <li>Rf = Risk-Free Rate</li>
                  <li>σp = Standard Deviation of Portfolio (Risk)</li>
                </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <div style={{paddingLeft:'15px'}}>
                <div style={{marginBottom:'10px'}}><small><b>Annual Return(%)</b></small></div>
                <TextField type='number' variant='outlined' className={`without-padding`} inputProps={{ min: -1000000, max: 1000000}} value={Number(annualReturn).toString()} onChange={(e) => {
                    if (Number(e.target.value) && Number(e.target.value) >= 0) {
                      setAnnualReturn(e.target.value);
                    }
                }} />
                </div>
            </Grid>
            <Grid item>
                <div style={{marginBottom:'10px'}}><small><b>Risk Free Rate (%)</b></small></div>
                <TextField type='number' variant='outlined' className={`without-padding`} inputProps={{ min: -1000000, max: 1000000}} value={Number(riskFreeRate).toString()} onChange={(e) => {
                    if (Number(e.target.value) && Number(e.target.value) >= 0) {
                        setRiskFreeRate(e.target.value);
                    }
                }} />
            </Grid>
            <Grid item>
                <div style={{marginBottom:'10px'}}><small><b>Standard Deviation (%)</b></small></div>
                <TextField type='number' variant='outlined' className={`without-padding`} inputProps={{ min: -1000000, max: 1000000}} value={Number(standardDeviation).toString()} onChange={(e) => {
                    if (Number(e.target.value) && Number(e.target.value) >= 0) {
                        setStandardDeviation(e.target.value);
                    }
                }} />
            </Grid>
            <Grid item>
                <div style={{marginBottom:'10px'}}><small><b>&nbsp;</b></small></div>
                <div style={{marginTop:'20px'}}>=</div>
            </Grid>
            <Grid item>
                <div style={{marginBottom:'10px'}}><small><b>Sharpe Ratio</b></small></div>
                <TextField type='number' variant='outlined' className={`without-padding`} InputProps={{readOnly: true,min: -1000000, max: 1000000}} value={sharpeRatio} />
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>
      }
    </Container>
  );
}