import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";

const OptionObject = ({ children, ...props }) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = Object.assign(props, { innerProps: rest });
    return (
      <components.Option
        {...newProps}
      >
        {children}
      </components.Option>
    );
  };

export default function SingleSelect({ label, options, selected,components,id, name,  onChange, ...props }) {
    const customStyles = {
        container: (provided) => ({
            ...provided,
            display: 'inline-block',
            minHeight: '1px',
            textAlign: 'left',
            border: 'none',
            minWidth: props.minWidth ? props.minWidth : '100%',
            maxWidth: '100%'
        }),
        placeholder: (provided) => ({
            ...provided,
            minHeight: '1px',
            padding: '0px 6px',
            height: props.height ? props.height : '24px',
            //color: '#B9C1C8'
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            minHeight: '1px',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
        }),
        valueContainer: (provided) => ({
            ...provided,
            textOverflow: "ellipsis",
            maxWidth: "95%",
            padding: '0px 6px',
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "initial",
            height: props.height ? props.height : '24px',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            minHeight: '1px',
            paddingTop: '0',
            paddingBottom: '0',
            //color: '#757575',
        }),
        control: (provided) => ({
            ...provided,
            minHeight: '1px',
            height: props.height ? props.height : '24px',
            //backgroundColor: '#11181E',
            minWidth: '100%',
            borderRadius: 0,
            maxWidth: '100%',
            //color: '#00E3AE',
            //border: 'none'
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 10
        }),
        clearIndicator: (provided) => ({
            ...provided,
            minHeight: '1px',
            padding: '0',
            //color: '#757575',
        }),
        input: (provided) => ({
            ...provided,
            minHeight: '1px',
            maxWidth: '100%',
            minWidth: '100%',
            height: props.height ? props.height : '24px',
            margin: '0px 0px auto 0px'
        }),
    };

    return (
        <div>
            {
                label && label.length > 0 &&
                <h5>{label}</h5>
            }
            <Select
                id = {id}
                name = {name}
                options={options}
                captureMenuScroll={false}
                styles={customStyles}
                hideSelectedOptions={false}
                onFocus={(e) => {
                    //console.log('Focus', e.target)
                }}
                onBlur={(e) => {
                    //console.log('Blur', e.target);
                }}
                isClearable={false}
                components={ components==null?(OptionObject):(components) }
                isSearchable={props.isSearchable ? true : false}
                onChange={(selected, actions) => onChange(selected)}
                value={selected}
            />
        </div>
    )
}