import React, { useEffect, useState } from 'react'
import {
    Grid, TextField,
    Button, Modal, Fade, Backdrop, Avatar, CircularProgress, IconButton, TableContainer, TableBody, Table, TableRow, TableCell, Checkbox
} from "@material-ui/core";
import { RuleCompareMode, SignalMode, SignalOperation, TranslateSignal } from '../../logic/Backtester';
import hash from 'object-hash'
import SingleSelect from '../../Components/SingleSelect';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const signalModeOptions = [
    { label: 'AND', value: SignalMode.And },
    { label: 'OR', value: SignalMode.Or },
]

const compareOptions = [
    { label: '=', value: RuleCompareMode.Equal },
    //{ label: 'Between', value: RuleCompareMode.Between },
    { label: '>', value: RuleCompareMode.Greater },
    { label: '<', value: RuleCompareMode.Less },
    { label: '>=', value: RuleCompareMode.GreaterEqual },
    { label: '<=', value: RuleCompareMode.LessEqual }
]

const operationOptions = [
    { label: '+', value: SignalOperation.Add },
    { label: '-', value: SignalOperation.Minus },
    { label: '*', value: SignalOperation.Multiple },
    { label: '/', value: SignalOperation.Divide }
]

export const dataKeyOptions = [
    { label: 'Number', value: 'number' },
    { label: 'Close', value: 'lastClose' },
    { label: 'RSI(14)', value: 'rsi', type: 'RSI' },
    { label: 'Stochastics(14)', value: 'stochastics', type: 'Stochastics' },
    { label: 'MACD(12,26,9)', value: 'macd', type: 'MACD' },
    { label: 'UpperBB(20,2)', value: 'bolupper', type: 'UpperBB' },
    { label: 'LowerBB(20,2)', value: 'boldown', type: 'LowerBB' },
    { label: 'SMA5', value: 'sma5', type: 'SMA' },
    { label: 'SMA10', value: 'sma10', type: 'SMA' },
    { label: 'SMA20', value: 'sma20', type: 'SMA' },
    { label: 'SMA50', value: 'sma50', type: 'SMA' },
    { label: 'SMA200', value: 'sma200', type: 'SMA' },
    { label: 'Consecutive', value: 'consecutiveClose' },
    { label: 'Gap %', value: 'gapPercent', type: 'Gap' },
    { label: 'Exact Day', value: 'exactDay' },
    { label: 'Day of Week', value: 'dayOfWeek' },
    { label: 'Day of Month', value: 'dayOfMonth' },
    { label: 'Week of Month', value: 'weekOfMonth' },
    { label: 'Month', value: 'month' },
    { label: 'Quarter', value: 'quarter' },
    { label: 'Previous Day', value: 'prevPercentChange', type: 'Percent Change' },
    { label: 'Relative Volume', value: 'relativeVolume' },
    { label: 'MTD', value: 'monthToDate' },
    { label: 'QTD', value: 'quarterToDate' },
    { label: 'YTD', value: 'yearToDate' }
]

function GetEmptyAtom() {
    let atom = {
        op: SignalOperation.Add,
        dataKey: 'lastClose',
        label: 'Close',
        lookback: 0,
        value: 0,
        timestamp: Date.now(),
    }
    atom.id = hash(atom);

    return atom;
}

function GetEmptyNumberAtom() {
    let atom = {
        op: SignalOperation.Add,
        dataKey: 'number',
        label: 'Number',
        lookback: 0,
        value: 0,
        timestamp: Date.now(),
    }
    atom.id = hash(atom);

    return atom;
}

function GetEmptySignal(signalMode) {
    let newSignal = {
        timestamp: Date.now(),
        mode: signalMode,
        children: []
    }
    if (signalMode === SignalMode.Rule) {
        newSignal = {
            ...newSignal,
            atoms: [
                GetEmptyAtom()
            ],
            rightAtoms: [
                GetEmptyNumberAtom()
            ],
            compareMode: RuleCompareMode.Equal,
            lower: 0,
            upper: 0
        }
    }
    newSignal.id = hash(newSignal);

    return newSignal;
}

function CustomRuleEditor(props) {
    const [signal, setSignal] = useState(props.signal);

    const updateAtom = (atom, isRight = false) => {
        if (isRight) {
            let id = signal.rightAtoms.findIndex(x => x.id === atom.id);
            let newSignal = { ...signal }
            newSignal.rightAtoms[id] = atom;
            setSignal(newSignal)
        } else {
            let id = signal.atoms.findIndex(x => x.id === atom.id);
            let newSignal = { ...signal }
            newSignal.atoms[id] = atom;
            setSignal(newSignal)
        }
    }

    let keyOptions = [...dataKeyOptions]
    if (!props.onNextOpen) keyOptions = keyOptions.filter(x => x.value !== 'gapPercent')

    let selectedCompare = { label: '=', value: RuleCompareMode.Equal };
    if (signal.compareMode) {
        selectedCompare = compareOptions.find(x => x.value === signal.compareMode)
    }

    return (
        <div>
            <div className='border p-1' style={{ minHeight: 40 }}>
                {TranslateSignal(signal)}
            </div>
            <div className='pt-2'>
                Left Expression:
            </div>
            {
                signal.atoms && signal.atoms.map((atom, idx) => {
                    let selectedOption = operationOptions.find(x => x.value === atom.op);

                    let selectedData = keyOptions.find(x => x.value === atom.dataKey);

                    return (
                        <div key={atom.id} className='py-1 w-100 d-inline-flex'>
                            {
                                (idx > 0) ? (
                                    <div style={{ marginTop: 3 }}>
                                        <SingleSelect minWidth={'55px'} height={'28px'} options={operationOptions} selected={selectedOption} onChange={(selected) => {
                                            let newAtom = { ...atom, op: selected.value }
                                            updateAtom(newAtom);
                                        }} />
                                    </div>
                                ) : (
                                    <div style={{ width: 55 }}></div>
                                )
                            }
                            {
                                <div style={{ marginTop: 3, marginLeft: 6 }}>
                                    <SingleSelect options={keyOptions} height={'28px'} minWidth={'150px'} selected={selectedData} onChange={(selected) => {
                                        let newAtom = { ...atom, dataKey: selected.value, label: selected.label }
                                        updateAtom(newAtom);
                                    }} />
                                </div>
                            }
                            <TextField
                                className='ml-1 mt-1'
                                value={(atom.dataKey === 'number' ? atom.value : atom.lookback) || 0}
                                onChange={(e) => {
                                    let newAtom = { ...atom };
                                    if (atom.dataKey === 'number') {
                                        newAtom.value = Number(e.target.value)
                                    } else {
                                        newAtom.lookback = Number(e.target.value)
                                    }
                                    updateAtom(newAtom)
                                }}
                                InputProps={{
                                    inputProps: { min: atom.dataKey === 'number' ? undefined : 0 }
                                }}
                                type="number"
                                variant="outlined"
                                margin="dense"
                                style={{ width: 80 }}
                            />
                            <div className='ml-auto'>
                                <Button size='small' variant='outlined' style={{ minWidth: 30, height: 30, marginTop: 2, marginLeft: 6 }}
                                    onClick={() => {
                                        let newAtom = GetEmptyAtom();
                                        let newSignal = { ...signal }
                                        newSignal.atoms.splice(idx + 1, 0, newAtom)
                                        setSignal(newSignal)
                                    }}
                                >+</Button>
                                {
                                    idx > 0 &&
                                    <Button size='small' variant='outlined' style={{ minWidth: 30, height: 30, marginTop: 2, marginLeft: 6 }}
                                        onClick={() => {
                                            let newSignal = { ...signal }
                                            newSignal.atoms.splice(idx, 1)
                                            setSignal(newSignal)
                                        }}
                                    >-</Button>
                                }
                            </div>
                        </div>
                    )
                })
            }
            <div className='d-inline-flex w-100 pt-1 pb-2 border-top border-bottom'>
                <div className='my-auto' style={{ width: 90 }}>
                    Compare:
                </div>
                <div style={{ paddingTop: 3 }}>
                    <SingleSelect options={compareOptions} selected={selectedCompare} minWidth={'120px'} height={'28px'} onChange={(selected) => {
                        let newSignal = { ...signal }
                        newSignal.compareMode = selected.value;
                        setSignal(newSignal);
                    }} />
                </div>
                {
                    /*
                    (signal.compareMode === RuleCompareMode.Equal ||
                        signal.compareMode === RuleCompareMode.Between ||
                        signal.compareMode === RuleCompareMode.GreaterEqual ||
                        signal.compareMode === RuleCompareMode.Greater) &&
                    <TextField
                        className='ml-1 mb-0'
                        value={signal.lower || 0}
                        onChange={(e) => {
                            let newSignal = { ...signal, lower: Number(e.target.value) }
                            setSignal(newSignal)
                        }}
                        type="number"
                        variant="outlined"
                        margin="dense"
                        style={{ width: 100 }}
                    />
                    */
                }
                {
                    /*
                    (signal.compareMode === RuleCompareMode.Between) &&
                    <span className='my-auto ml-1'>and</span>
                    */
                }
                {
                    /*
                    (signal.compareMode === RuleCompareMode.Between ||
                        signal.compareMode === RuleCompareMode.LessEqual ||
                        signal.compareMode === RuleCompareMode.Less) &&
                    <TextField
                        className='ml-1 mb-0'
                        value={signal.upper || 0}
                        onChange={(e) => {
                            let newSignal = { ...signal, upper: Number(e.target.value) }
                            setSignal(newSignal)
                        }}
                        type="number"
                        variant="outlined"
                        margin="dense"
                        style={{ width: 100 }}
                    />
                    */
                }
            </div>
            <div className='pt-2'>
                Right Expression:
            </div>
            {
                signal.rightAtoms && signal.rightAtoms.map((atom, idx) => {
                    let selectedOption = operationOptions.find(x => x.value === atom.op);

                    let selectedData = keyOptions.find(x => x.value === atom.dataKey);

                    return (
                        <div key={atom.id} className='py-1 w-100 d-inline-flex'>
                            {
                                (idx > 0) ? (
                                    <div style={{ marginTop: 3 }}>
                                        <SingleSelect minWidth={'55px'} height={'28px'} options={operationOptions} selected={selectedOption} onChange={(selected) => {
                                            let newAtom = { ...atom, op: selected.value }
                                            updateAtom(newAtom, true);
                                        }} />
                                    </div>
                                ) : (
                                    <div style={{ width: 55 }}></div>
                                )
                            }
                            {
                                <div style={{ marginTop: 3, marginLeft: 6 }}>
                                    <SingleSelect options={keyOptions} height={'28px'} minWidth={'150px'} selected={selectedData} onChange={(selected) => {
                                        let newAtom = { ...atom, dataKey: selected.value, label: selected.label }
                                        updateAtom(newAtom, true);
                                    }} />
                                </div>
                            }
                            <TextField
                                className='ml-1 mt-1'
                                value={(atom.dataKey === 'number' ? atom.value : atom.lookback) || 0}
                                onChange={(e) => {
                                    let newAtom = { ...atom };
                                    if (atom.dataKey === 'number') {
                                        newAtom.value = Number(e.target.value)
                                    } else {
                                        newAtom.lookback = Number(e.target.value)
                                    }
                                    updateAtom(newAtom, true)
                                }}
                                InputProps={{
                                    inputProps: { min: atom.dataKey === 'number' ? undefined : 0 }
                                }}
                                type="number"
                                variant="outlined"
                                margin="dense"
                                style={{ width: 80 }}
                            />
                            <div className='ml-auto'>
                                <Button size='small' variant='outlined' style={{ minWidth: 30, height: 30, marginTop: 2, marginLeft: 6 }}
                                    onClick={() => {
                                        let newAtom = GetEmptyAtom();
                                        let newSignal = { ...signal }
                                        newSignal.rightAtoms.splice(idx + 1, 0, newAtom)
                                        setSignal(newSignal)
                                    }}
                                >+</Button>
                                {
                                    idx > 0 &&
                                    <Button size='small' variant='outlined' style={{ minWidth: 30, height: 30, marginTop: 2, marginLeft: 6 }}
                                        onClick={() => {
                                            let newSignal = { ...signal }
                                            newSignal.rightAtoms.splice(idx, 1)
                                            setSignal(newSignal)
                                        }}
                                    >-</Button>
                                }
                            </div>
                        </div>
                    )
                })
            }
            <div className='text-center pt-3'>
                <Button variant='contained' color='primary' size='small' onClick={() => {
                    if (props.onChange) {
                        props.onChange(signal);
                    }
                }}>Add</Button>
            </div>
        </div>
    )
}

export default function CustomSignalEditor(props) {
    const [signal, setSignal] = useState(props.signal || GetEmptySignal(SignalMode.And))
    const [atomEditorOpen, setAtomEditorOpen] = useState(false);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(signal)
        }
    }, [signal])

    const handleClose = () => {
        setAtomEditorOpen(false);
    }

    let selectedSignalMode = null;
    if (signal) selectedSignalMode = signalModeOptions.find(x => x.value === signal.mode)

    return (
        <>
            {
                atomEditorOpen &&
                <Modal open={atomEditorOpen}
                    onClose={handleClose}
                >
                    <div className='atom-editor-modal'>
                        <CustomRuleEditor signal={signal} onNextOpen={props.onNextOpen} onChange={(signal) => {
                            setSignal({ ...signal })
                            setAtomEditorOpen(false);
                        }}></CustomRuleEditor>
                    </div>
                </Modal>
            }
            {
                signal.mode === SignalMode.Rule ? (
                    <div style={{ height: 38 }} className='pt-2'>
                        <span className='my-auto mr-2'>{TranslateSignal(signal)}</span>
                        <Button size='small' variant='outlined' className='icon-button mr-2' onClick={() => {
                            setAtomEditorOpen(true)
                        }}><EditOutlinedIcon /></Button>
                        <Button size='small' variant='outlined' className='icon-button' onClick={() => {
                            if (props.onDelete) {
                                props.onDelete(signal);
                            }
                        }}><DeleteForeverOutlinedIcon /></Button>
                    </div>
                ) : (
                    <div>
                        <div className='d-inline-flex w-100 pt-2'>
                            <div className='my-auto' style={{ width: 80 }}>
                                <SingleSelect options={signalModeOptions} selected={selectedSignalMode} minWidth={'80px'} height={'30px'} onChange={(selected) => {
                                    let newSignal = { ...signal }
                                    newSignal.mode = selected.value;
                                    setSignal(newSignal);
                                }} />
                            </div>
                            <div className='ml-2 my-auto'><Button variant='outlined' size='small' onClick={() => {
                                let child = GetEmptySignal(SignalMode.And)
                                let newSignal = { ...signal, children: [...signal.children, child] }
                                setSignal(newSignal)
                            }}>+ Group</Button></div>
                            <div className='ml-2 my-auto'><Button variant='outlined' size='small' onClick={() => {
                                let child = GetEmptySignal(SignalMode.Rule)
                                let newSignal = { ...signal, children: [...signal.children, child] }
                                setSignal(newSignal)
                            }}>+ Rule</Button></div>
                            {
                                !props.isRoot &&
                                <div className='ml-2 my-auto'><Button variant='outlined' size='small' className='icon-button' onClick={() => {
                                    if (props.onDelete) {
                                        props.onDelete(signal);
                                    }
                                }}><DeleteForeverOutlinedIcon /></Button></div>
                            }
                        </div>
                        {
                            signal.children && signal.children.length > 0 &&
                            <div className='d-inline-box w-100'>
                                <div style={{ width: 0.5, backgroundColor: 'grey', marginBottom: 15, borderLeft: '0.5px solid grey' }}></div>
                                <div>
                                    {
                                        signal.children.map((child) => {
                                            return (
                                                <div key={child.id} className='d-inline-box w-100'>
                                                    <div className='tree-tick'></div>
                                                    <div>
                                                        <CustomSignalEditor isRoot={false} signal={child}
                                                            onNextOpen={props.onNextOpen}
                                                            onChange={(child) => {
                                                                let newSignal = { ...signal }
                                                                let id = newSignal.children.findIndex(x => x.id === child.id)
                                                                newSignal.children[id] = child;
                                                                setSignal(newSignal);
                                                            }}
                                                            onDelete={(child) => {
                                                                let newSignal = { ...signal }
                                                                let id = newSignal.children.findIndex(x => x.id === child.id)
                                                                newSignal.children.splice(id, 1);
                                                                setSignal(newSignal)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                )
            }
        </>
    )
}