import React from 'react';
import Box from '@material-ui/core/Box';
import { Redirect, Route, Switch } from 'react-router-dom';
import Backtester from './pages/Backtester';
import RiskRewardSimulator from './pages/RiskRewardSimulator';
import EmailVerification from './pages/EmailVerification';
import SharpeRatioCalculator from './pages/SharpeRatioCalculator';
export default function App() {
  return (
    <Box my={0}>
      <Switch>
        <Route path="/RiskRewardSimulator/:userid" exact component={RiskRewardSimulator} />
        <Route path="/EmailVerfication/:encdata" exact component={EmailVerification} />
        <Route path="/Backtester/:userid" exact component={Backtester} />
        <Route path="/SharpeRatioCalculator/:userid" exact component={SharpeRatioCalculator} />
      </Switch>
    </Box>
  );
}
