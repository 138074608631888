import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import { Modal, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Grid, Button, Container, Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';

import { GetJWTTokenString,getRequestSeasonAPI,getRequestVerifyEmail } from '../../logic/common';

export default function Backtester(props) {
    const { encdata } = useParams();
    const [checked, setChecked] = useState(false)
    const [checkResult, setCheckResult] = useState(false)
    const [email, setEmail] = useState('')
    useEffect(() => {
        const cb=(res)=>{
            //console.log(res['data']);
            setChecked(true);
            if (res['data'][0]['result']=='true')
            {
                setCheckResult(true);
                setEmail(res['data'][0]['email']);
            }else{
                setCheckResult(false);
            }
        }

        //getRequestSeasonAPI("getSymbols",cb);
        getRequestVerifyEmail("vertifyemail?encdata="+encdata,cb);
    }, []);
    return (
        <Container maxWidth='md'  >
            {
                checked?(
                    checkResult?(
                        <div style={{ width: '100%', fontSize: '0.875rem' }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div style={{textAlign:"center",paddingTop:"50px"}}>
                                        <span style={{fontSize:"18pt"}}>Email address </span><span style={{fontSize:"26pt",color:"blue"}}>{email}</span><span style={{fontSize:"20pt"}}> has been verified.</span>
                                    </div>
                                    <div style={{textAlign:"center",paddingTop:"30px"}}>
                                        <img width={"150px"} src="https://icon-library.com/images/green-check-mark-icon-png/green-check-mark-icon-png-28.jpg"></img>
                                    </div>
                                </Grid>
                            </Grid>
                        </div >
                    ):(
                        <div style={{ width: '100%', fontSize: '0.875rem' }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div style={{textAlign:"center",paddingTop:"50px"}}>
                                        <span style={{fontSize:"18pt"}}>Invaild request.</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div >
                    )
                
            ):(
                <div style={{ width: '100%', fontSize: '0.875rem' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div style={{textAlign:"center",paddingTop:"50px"}}>
                                <span style={{fontSize:"18pt"}}>Waiting response...</span>
                            </div>
                        </Grid>
                    </Grid>
                </div >
            )
            }
            
       </Container >
    );
}