import { TextField, Paper, Backdrop, CircularProgress, Grid, Container, IconButton, Modal,
    Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TablePagination, TableSortLabel, Hidden, Button, Box } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import hash from 'object-hash'
import { UpdateSessionUsages } from "../logic/counterutil";
export default function SimpleMsg(props) {
    const {open,handleClose} = props;
    return (
        <Modal
                open={open}
            >
                <div className='email-modal'>
                    <div className='text-center'>
                        <h3>Thank you!</h3>
                        <div>
                            <p>
                                Verification email was sent. Please confirm verification email in your MailBox.
                            </p>
                        </div>
                        <div style={{ width: 300 }} className='pt-3 mx-auto'>
                            <Button color='primary' variant='contained' onClick={() => {
                                
                                handleClose()
                                
                            }}>OK</Button>
                        </div>
                    </div >
                </div>
            </Modal>
        
    )
}