import { Button, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import hash from 'object-hash'
import { UpdateSessionUsages } from "../logic/counterutil";

import { GetJWTTokenString,getRequestSeasonAPI,getRequestSeasonAPIJSON,validateEmail} from '../logic/common';

export default function EmailCapture(props) {
    const {email,onClose,onChange} = props
    let inValid = email != null && email != undefined && email.length > 0 && !validateEmail(email)
    
    return (
        <div className='text-center'>
            <h3>EMAIL VERIFICATION</h3>
            <div>
                <p>
                    Please register email and verify it.
                </p>
            </div>
            <div style={{ width: 300 }} className='pt-3 mx-auto'>
                <TextField error={inValid} fullWidth value={email} placeholder='Email Address' onChange={e => onChange(e.target.value)}></TextField>
            </div>
            <div style={{ width: 300 }} className='pt-3 mx-auto'>
                <Button color='primary' variant='contained' onClick={onClose}>Send Verification Email</Button>
            </div>
        </div >
    )
}