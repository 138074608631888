import jwt from 'jsonwebtoken'
import axios from 'axios';
import { Jwt_Key,API_KEY, API_SERVER,BK_SERVER } from '../config';
import Cookies from 'js-cookie';
export function GetJWTTokenString() {
    return jwt.sign({
        timestamp: Math.floor(Date.now() / 1000)
    }, Jwt_Key)
}

export function sum(vec) {
    return vec.reduce((a, b) => a + b, 0)
}

export function mean(vec) {
    return vec.length === 0 ? 0 : (sum(vec) / vec.length);
}

export function std(vec) {
    if (vec.length === 0) { return 0; }
    let dev = 0;
    vec.forEach(x => dev += x * x);
    let m = mean(vec);
    let v = dev / vec.length - m * m;
    if (v > 0)
        return Math.sqrt(v);
    else return 0;
}

export function make_pair(a, b) {
    return {
        first: a,
        second: b
    }
}

export function max_element(vec) {
    if (vec.length === 0) return 0;
    let max = vec[0];

    for (let val of vec) {
        if (val > max) {
            max = val;
        }
    }
    return max;
}

export function min_element(vec) {
    if (vec.length === 0) return 0;
    let min = vec[0];

    for (let val of vec) {
        if (val < min) {
            min = val;
        }
    }
    return min;
}

export function percentile(sorted, q) {
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
}

export function Correlation(Xs, Ys) {
    let sumX = 0, sumX2 = 0, sumY = 0, sumY2 = 0, sumXY = 0;

    let n = Xs.length < Ys.length ? Xs.length : Ys.length;
    let n2 = n * n;

    for (var i = 0; i < n; ++i) {
        let x = Xs[i];
        let y = Ys[i];

        sumX += x;
        sumX2 += x * x;
        sumY += y;
        sumY2 += y * y;
        sumXY += x * y;
    }

    let stdX = Math.sqrt(sumX2 / n - sumX * sumX / n2);
    let stdY = Math.sqrt(sumY2 / n - sumY * sumY / n2);
    let covariance = (sumXY / n - sumX * sumY / n2);

    return covariance / stdX / stdY;
}

export function callGetAPI(api,parameter,cb,cth=null)
{
    let url = "https://"+API_SERVER+"/api/"+api+"?apiKey=a0813546a83bb3a2194c1646c3a788e64323bd6b";
    if (parameter!="")
    {
        url = url+"&"+parameter;
    }
}



export function getRequestVerifyEmail(url,cb,cth=null)
{
    
    let headers={
        headers: {
            token: GetJWTTokenString(),
            euser:sessionStorage.getItem("__SC__LOGGEDIN__"),
            'Email-Verification-Request' : GetJWTTokenString()
        }
    };
    axios.get(BK_SERVER+url,headers).then((res) => {
        cb(res);
    }).catch(function (error) 
    {
        if (error.response) {
            if(cth!=null)
            {
                cth();
            }
            console.log(error);
            if(error.response.status==401)
            {
                console.log("401 error");
            }  
        } 
    });
}

export function getRequestSeasonAPI(url,cb,cth=null)
{
    
    let headers={
        headers: {
            token: GetJWTTokenString(),
            euser:sessionStorage.getItem("__SC__LOGGEDIN__")
        }
    };
    axios.get(BK_SERVER+url,headers).then((res) => {
        cb(res);
    }).catch(function (error) 
    {
        if (error.response) {
            if(cth!=null)
            {
                cth();
            }
            console.log(error);
            if(error.response.status==401)
            {
                console.log("401 error");
            }  
        } 
    });
}

export function postRequestSeasonAPI(url,data,cb,cth=null)
{
   
    let headers={
        headers: {
            token: GetJWTTokenString(),
            euser:sessionStorage.getItem("__SC__LOGGEDIN__")
        }
    };
    axios.post(BK_SERVER+url,data,headers).then((res) => {
        cb(res);
    }).catch(function (error) 
    {
        if (error.response) {
            if(cth!=null)
            {
                cth();
            }
            console.log(error);
            if(error.response.status==401)
            {
                console.log("401 error");
            }  
        } 
    });
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}