import React from "react";
import Plot from 'react-plotly.js';
import { Paper } from "@material-ui/core";
import { barchartlayout, totalchartconfig } from "../../logic/chartutils";

const ChartByMonth = (props) => {
  let months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
  let layout = { ...barchartlayout }
  let title = { ...barchartlayout.title }
  title.text = "P&L by Month"
  layout.title = title;

  let values = []
  let colors = []
  let greencolor = '#26a69a'
  let redcolor = '#ef5350'
  for (var id = 1; id <= 12; id++) {
    values.push((props.data && props.data[id]) ? props.data[id] : 0)
    if (values[id - 1] >= 0) colors.push(greencolor)
    else colors.push(redcolor);
  }
  return (
    <Paper className="p-2">
      <div style={{ width: "100%", height: props.height }}>
        <Plot
          data={[
            {
              type: 'bar', x: months, y: values, marker: {
                color: colors,
              }
            },
          ]}
          layout={layout}
          style={{ width: "100%", height: "100%" }}
          config={totalchartconfig}
        />
      </div>
    </Paper>
  );
};

export default ChartByMonth;
