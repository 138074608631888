import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useParams } from "react-router-dom";

import {
    TextField, Paper, Backdrop, CircularProgress, Grid, Container, IconButton, Modal,
    Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TablePagination, TableSortLabel, Hidden, Button, Box
} from '@material-ui/core'

import Typography from '@material-ui/core/Typography';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import { Line } from 'react-chartjs-2';


import EmailCaptureContainer from '../../Components/EmailCaptureContainer';
import SimpleMsg from "../../Components/SimpleMsg";

import {mobileCheck } from '../../logic/apiutils';


import '../../custom.css';

import { GetJWTTokenString,getRequestSeasonAPI,validateEmail ,postRequestSeasonAPI} from '../../logic/common';

import Cookies from 'js-cookie';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );



export default function RiskRewardSimulator(props) {
    
    const { userid } = useParams();

    
    const [loading, setLoading] = useState(false);  
    const [emailCaptureOpen, setEmailCaptureOpen] = useState(false);
    const [msgboxOpen, setMsgboxOpen] = useState(false);

    const [accessDenied, setAccessDenied] = useState(false);
    
    const [company, setCompanyInfo] = useState(null);

    const [winrate, setWinRate] = useState(50);
    const [avggain, setAvgGain] = useState(250);
    const [avgloss, setAvgLoss] = useState(250);
    const [startvalue, setStartValue] = useState(10000);
    const [simcnt, setSimCnt] = useState(5);
    const [tradecnt, setTradeCnt] = useState(2000);

    const [redraw, setRedraw] = useState(false);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
            elements: {
                point:{
                    radius: 0
                }
            }
        },
      };

    const [chartdata,setChartData] = useState(null);
    //let chartdata = null;

    const HsvToRgb=(h, S, V)=>
    {
        var r,g,b
        var H = h;
        while (H < 0) { H += 360; };
        while (H >= 360) { H -= 360; };
        var R, G, B;
        if (V <= 0)
        { R = G = B = 0; }
        else if (S <= 0)
        {
            R = G = B = V;
        }
        else
        {
            var hf = H / 60.0;
            var i = Math.floor(hf);
            var f = hf - i;
            var pv = V * (1 - S);
            var qv = V * (1 - S * f);
            var tv = V * (1 - S * (1 - f));
            switch (i)
            {

                // Red is the dominant color

                case 0:
                    R = V;
                    G = tv;
                    B = pv;
                    break;

                // Green is the dominant color

                case 1:
                    R = qv;
                    G = V;
                    B = pv;
                    break;
                case 2:
                    R = pv;
                    G = V;
                    B = tv;
                    break;

                // Blue is the dominant color

                case 3:
                    R = pv;
                    G = qv;
                    B = V;
                    break;
                case 4:
                    R = tv;
                    G = pv;
                    B = V;
                    break;

                // Red is the dominant color

                case 5:
                    R = V;
                    G = pv;
                    B = qv;
                    break;

                // Just in case we overshoot on our math by a little, we put these here. Since its a switch it won't slow us down at all to put these here.

                case 6:
                    R = V;
                    G = tv;
                    B = pv;
                    break;
                case -1:
                    R = V;
                    G = pv;
                    B = qv;
                    break;

                // The color is not defined, we should throw an error.

                default:
                    //LFATAL("i Value error in Pixel conversion, Value is %d", i);
                    R = G = B = V; // Just pretend its black/white
                    break;
            }
        }
        r = Clamp(Math.floor(R * 255.0));
        g = Clamp(Math.floor(G * 255.0));
        b = Clamp(Math.floor(B * 255.0));
        return 'rgb('+r+','+g+','+b+')';
    }

    /// <summary>
    /// Clamp a value to 0-255
    /// </summary>
    const Clamp=(i)=>
    {
        if (i < 0) return 0;
        if (i > 255) return 255;
        return i;
    }

    const simulateone=()=>
    {
        let data=[];
        let curvalue = startvalue;
        data.push({x:0,y:curvalue});
        for(var i = 1 ; i<tradecnt ; i++)
        {
            var t = Math.random()*100;
            if(t<winrate)
            {
                curvalue += parseInt(avggain);
            }else{
                curvalue -= parseInt(avgloss);
            }
            data.push({x:i,y:curvalue});
        }
        return data;
    }
    
    
    const startsimulate = (event,txt) => {
        makeIntradayGraph();        
        const cb=(res)=>{
            //console.log(res);
            var cnt =  res['data'][0]['result']['cnt'];
            var email =  res['data'][0]['result']['email'];
            if(parseInt(cnt)>5)
            {
                if(email=='')
                {
                    setEmailCaptureOpen(true);
                }
                
            }
        }
        const data = { userid: userid};
        postRequestSeasonAPI("usecount",data,cb);
    };

    const makeIntradayGraph=()=>
    {
        let graphdata=[];
        for(var i = 0 ; i < simcnt ; i++)
        {
            var data = simulateone();
            graphdata.push(data);
        }
        //console.log(graphdata);
        let labels = [];
        for(var i = 0 ; i<tradecnt ; i++)
        {
            labels.push(i+"");
        }

        var clrs = [];
        clrs.push('rgb(255,0,255)');
        clrs.push('rgb(0,255,255)');
        clrs.push('rgb(0,255,0)');
        clrs.push('rgb(255,255,0)');

        var h = Math.random()*280+40;

        if (simcnt > 4)
        {
            for (var i = 0; i < simcnt - 3; i++)
            {
                h = (h + (280 / simcnt) * i) % 320;
                var r = 0, g = 0, b = 0;
                var rgb = HsvToRgb(h, 0.83, 1);
                var c = rgb;
                clrs.push(c);
            }
        }

        var ds = [];

        for(var i = 0 ; i<simcnt ; i++)
        {
            ds.push({
                data: graphdata[i],
                borderColor: clrs[i],
                pointRadius: 0,
                borderWidth:1
                });
        }
        
        setChartData({labels:labels,datasets:ds});
       //chartdata = {labels:labels,datasets:ds};
       //setRedraw(true);
    }
    const closeMsgbox=()=>
    {
        setMsgboxOpen(false);
    }
    const emailCallback=(res)=>
    {
        setLoading(false);
        setMsgboxOpen(true);
    }
    const onEmailContainerClose=()=>
    {
        setEmailCaptureOpen(false);
        setLoading(true);
    }
    useEffect(() => {
        
    }, []);

    return (
        <Container maxWidth='md'  >
            <EmailCaptureContainer open={emailCaptureOpen} onClose={onEmailContainerClose} callback={emailCallback} userid={userid}></EmailCaptureContainer>
            <SimpleMsg open={msgboxOpen} handleClose={closeMsgbox}/>
                <div style={{ width: '100%', fontSize: '0.875rem' }}>
                    {
                        accessDenied === false ? (
                            <Grid container spacing={1}>
                                <Backdrop open={loading} style={{ zIndex: 1000 }}>
                                    <CircularProgress style={{ color: 'blue' }} /> <span style={{ color: 'blue' }}>&nbsp;&nbsp;<b>Loading...</b></span>
                                </Backdrop>
                                <Grid item xs={12}>
                                    {
                                        company ? (
                                            <div className='d-inline-flex' style={{ height: 78 }}>
                                                <img src={company.photo} style={{ height: 72 }} className='my-auto'></img>
                                                <Typography variant="h4" component="h1" style={{ whiteSpace: 'nowrap', paddingLeft: 10 }} gutterBottom className='my-auto'>
                                                    {company.text || ''}
                                                </Typography>
                                            </div>
                                        ) : (
                                            <div style={{height:90}}>
                                                {mobileCheck()?(
                                                    <div style={{backgroundColor: '#EFEFEF', position: 'relative', display: 'flex',justifyContent: 'space-between', paddingTop: 15,paddingBottom: 15}}>
                                                        <div style={{display: 'flex',alignItems: 'center',marginLeft:15}}>       
                                                            <div>
                                                                <div>
                                                                    <span style={{fontFamily:'Arial', fontWeight:'Bold',fontSize:24}}>
                                                                        Risk reward simulator
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                ):(
                                                    <div style={{backgroundColor: '#EFEFEF', position: 'relative', display: 'flex',justifyContent: 'space-between', paddingTop: 15,paddingBottom: 15}}>
                                                        <div style={{display: 'flex',alignItems: 'center',marginLeft:15}}>
                                                            <span style={{fontFamily:'Arial', fontWeight:'Bold',fontSize:26,paddingTop:4}}>
                                                            Risk reward simulator
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                                }
                                                
                                            </div>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={10} style={{zIndex:200}} >
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <div style={{marginBottom:'10px'}}><small><b>Win Rate</b></small></div>
                                                    <TextField type='number' variant='outlined' className={`without-padding`} inputProps={{ min: 0, max: 100}} value={winrate} onChange={(e) => {
                                                        if (Number(e.target.value) && Number(e.target.value) >= 0) {
                                                            setWinRate(e.target.value);
                                                        }
                                                    }} />
                                                </Grid>
                                                <Grid item>
                                                    <div style={{marginBottom:'10px'}}><small><b>Avg Gain</b></small></div>
                                                    <TextField type='number' variant='outlined' className={`without-padding`} inputProps={{ min: 0, max: 1000000}} value={avggain} onChange={(e) => {
                                                        if (Number(e.target.value) && Number(e.target.value) >= 0) {
                                                            setAvgGain(e.target.value);
                                                        }
                                                    }} />
                                                </Grid>
                                                <Grid item>
                                                    <div style={{marginBottom:'10px'}}><small><b>Avg Loss</b></small></div>
                                                    <TextField type='number' variant='outlined' className={`without-padding`} inputProps={{ min: 0, max: 1000000}} value={avgloss} onChange={(e) => {
                                                        if (Number(e.target.value) && Number(e.target.value) >= 0) {
                                                            setAvgLoss(e.target.value);
                                                        }
                                                    }} />
                                                </Grid>
                                                <Grid item>
                                                    <div style={{marginBottom:'10px'}}><small><b>Starting Capital</b></small></div>
                                                    <TextField type='number' variant='outlined' className={`without-padding`} inputProps={{ min: 0, max: 1000000}} value={startvalue} onChange={(e) => {
                                                        if (Number(e.target.value) && Number(e.target.value) >= 0) {
                                                            setStartValue(e.target.value);
                                                        }
                                                    }} />
                                                </Grid>
                                                <Grid item>
                                                    <div style={{marginBottom:'10px'}}><small><b>No. of Simulations</b></small></div>
                                                    <TextField type='number' variant='outlined' className={`without-padding`} inputProps={{ min: 0, max: 1000000}} value={simcnt} onChange={(e) => {
                                                        if (Number(e.target.value) && Number(e.target.value) >= 0) {
                                                            setSimCnt(e.target.value);
                                                        }
                                                    }} />
                                                </Grid>
                                                <Grid item>
                                                    <div style={{marginBottom:'10px'}}><small><b>No. of Trades</b></small></div>
                                                    <TextField type='number' variant='outlined' className={`without-padding`} inputProps={{ min: 0, max: 1000000}} value={tradecnt} onChange={(e) => {
                                                        if (Number(e.target.value) && Number(e.target.value) >= 0) {
                                                            setTradeCnt(e.target.value);
                                                        }
                                                    }} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <div style={{marginBottom:'10px'}}><small><b>&nbsp; </b></small></div>
                                                    <Box>
                                                        <Button color="primary" style={{fontSize:"14pt",cursor:"pointer",paddingBottom:"0px"}} onClick={(e) => startsimulate(e, "clicked")}>Simulator</Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {chartdata==null?(<div></div>):(<Line options={options} data={chartdata} redraw={redraw}/>)}                                    
                                </Grid>
                            </Grid>
                        ) : (
                            <div className='text-center pt-5'>
                                <div>
                                    <img src='/img/favicon.png' width={100}></img>
                                </div>
                                <h3>Access Denied. Please contact support at <a href='mailto:david@axiomalerts.com'>david@axiomalerts.com</a></h3>
                            </div>
                        )
                    }
                </div >  
       </Container >
    );
}