import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, Grid, Button, Tooltip, Modal,
  Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails
} from '@material-ui/core';
import MultiToggle from 'react-multi-toggle';
import SingleSelect from '../../Components/SingleSelect'
import CustomSignalEditor from './CustomSignalEditor';
import { BuySellMode, SizingMethod, TradeMode } from '../../logic/Backtester';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Fragment } from 'react';

const marketOptions = [
  { label: 'Stocks', value: 'stocks' },
  { label: 'ETFs', value: 'etfs' },
  { label: 'Forex', value: 'fx' },
  { label: 'Crypto', value: 'crypto' }
]

let enterOptions = [
  {
    displayName: 'This Close',
    value: BuySellMode.OnClose,
  },
  {
    displayName: 'Next Open',
    value: BuySellMode.OnNextOpen,
  }
]

let exitOptions = [
  {
    displayName: 'This Close',
    value: BuySellMode.OnClose,
  },
  {
    displayName: 'Next Open',
    value: BuySellMode.OnNextOpen,
  }
]

let sizingOptions = [
  {
    displayName: 'Shares',
    value: SizingMethod.Share,
  },
  {
    displayName: 'Fixed Dollar',
    value: SizingMethod.FixedDollar,
  }
]

let tradeOptions = [
  {
    displayName: 'Long',
    value: TradeMode.Long,
  },
  {
    displayName: 'Short',
    value: TradeMode.Short,
  }
]

let onOffOptions = [
  {
    displayName: 'ON',
    value: 1,
  },
  {
    displayName: 'OFF',
    value: 0,
  }
]

export const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 32,
    '&$expanded': {
      minHeight: 32,
    },
  },
  content: {
    margin: '6px 0',
    '&$expanded': {
      margin: '6px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

class TesterSetting extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: null,
      tickerOptions: [],
      tipOpen: false,
      tipPage: 0,
      ...props.setting
    };

    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    this.populateTickerOptions(this.props.tickers);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!this.state.tickerOptions || !this.state.ticker || this.state.tickerOptions.length === 0) {
      this.populateTickerOptions(props.tickers);
    }
  }

  handleClose() {
    this.setState({
      tipOpen: false
    })
  }
  populateTickerOptions = (tickers) => {

    let tOptions = [
    ]
    tickers.forEach(ticker => {
      if (this.state.market.value === 'stocks') {
        if (ticker.market !== 'stocks' || ticker.type !== 'CS') return;
      } else if (this.state.market.value === 'etfs') {
        if (ticker.market !== 'stocks' || ticker.type !== 'ETF') return;
      } else if (this.state.market.value === 'crypto') {
        if (ticker.market !== 'crypto') return;
      } else if (this.state.market.value === 'fx') {
        if (ticker.market !== 'fx') return;
      }
      if (ticker.ticker.startsWith('X:') || ticker.ticker.startsWith('C:')) {
        tOptions.push({
          label: ticker.ticker.substring(2), value: ticker.ticker
        })
      } else {
        tOptions.push({
          label: ticker.ticker, value: ticker.ticker
        })
      }
    })

    if (tOptions.length > 0) {
      if (!this.state.ticker) {
        this.setState({
          tickerOptions: tOptions,
          ticker: tOptions[0]
        }, () => {
          this.props.onChange(this.state);
        })
      } else {
        this.setState({
          tickerOptions: tOptions,
        })
      }
    }
  }

  handleExpanded = newexpanded => event => {
    if (this.state.expanded === newexpanded) {
      this.setState({
        expanded: null
      })
    } else {
      this.setState({
        expanded: newexpanded
      })
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    }, () => {
      this.props.onChange(this.state);
    });
  };

  handleChangeSwitch = (name, value) => {
    this.setState({
      [name]: value,
    }, () => {
      this.props.onChange(this.state)
    });
  }

  handleChangeNumber = name => event => {
    if (event.target.value !== null && event.target.value.length > 0) {
      this.setState({
        [name]: Number(event.target.value),
      }, () => {
        this.props.onChange(this.state)
      });
    } else {
      this.setState({
        [name]: null,
      }, () => {
        this.props.onChange(this.state)
      });
    }
  };

  handleChangeBool = name => (event, checked) => {
    this.setState({
      [name]: checked ? 1 : 0,
    }, () => {
      this.props.onChange(this.state)
    });
  };

  tooltipModalContent = () => {
    return (
      <div >
        <div className='pt-1'>
          <h5>Close crosses above the 5-period Simple Moving Average</h5>
          <div className='pt-1'><b>Step 1: </b> Close of current bar [0] is greater than SMA5, Add</div>
          <div className='text-center'><img className='pt-1 border-solid' src='img/closegratersma.png' width={360}></img></div>
          <div className='pt-1'><b>Step 2: </b> Add a new Rule</div>
          <div className='text-center'><img className='pt-1 border-solid' src='img/addnew.png' width={360}></img></div>
          <div className='pt-1'><b>Step 3: </b> Previous bar’s Close [1] was less than previous bar’s SMA5[1]</div>
          <div className='text-center'><img className='pt-1 border-solid' src='img/closelesssmaone.png' width={360}></img></div>
        </div>

        <div className='pt-5'>
          <h5>RSI crosses above 70</h5>
          <div className='pt-1'><b>Step 1: </b> Current bar’s RSI[0] is greater than 70</div>
          <div className='text-center'><img className='pt-1 border-solid' src='img/rsigreater70.png' width={360}></img></div>
          <div className='pt-1'><b>Step 2: </b> Add a new Rule</div>
          <div className='text-center'><img className='pt-1 border-solid' src='img/rsiaddnew.png' width={360}></img></div>
          <div className='pt-1'><b>Step 3: </b> Previous Bar’s RSI[1] was less than 70</div>
          <div className='text-center'><img className='pt-1 border-solid' src='img/risless70.png' width={360}></img></div>
        </div>

        <div className='pt-5'>
          <h5>Percent Change greater than or equal to 4.5%</h5>
          <div className='text-center'><img className='pt-1 border-solid' src='img/percentchange.png' width={360}></img></div>
        </div>

        <div className='pt-5'>
          <h5>4-straight consecutive lower closes</h5>
          <div className='text-center'><img className='pt-1 border-solid' src='img/consecutive.png' width={360}></img></div>
        </div>
        <div className='pt-5'>
          <h5>Exact Day</h5>
          <div className='pt-1'>Note: Exact day is in mmdd format. July 28 becomes 728</div>
          <div className='text-center'><img className='pt-1 border-solid' src='img/exactday.png' width={360}></img></div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Fragment>
        {
          /*
          <Modal
            open={this.state.tipOpen}
            onClose={this.handleClose}
          >
            {this.tooltipModalContent()}
          </Modal>
          */
        }

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6" gutterBottom>
              Backtester Setting
              <button type="button" className="btn float-right" data-toggle="modal" data-target="#live-stats-feed">(i) Tips</button>
            </Typography>
            <div className="modal fade" id="live-stats-feed" tabIndex="-1" role="dialog" aria-labelledby="live-stats-feedTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content transparent">
                  <div className="modal-header">
                    <h4>Examples</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.tooltipModalContent()}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Accordion square expanded={this.state.expanded === 'symbol'} onChange={this.handleExpanded('symbol')}>
              <AccordionSummary>
                <Typography>Symbol</Typography>
                <span className='ml-auto'>{this.state.expanded === 'symbol' ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item>
                    <div><b><small>Market</small></b></div>
                    <SingleSelect options={marketOptions} selected={this.state.market} minWidth={'140px'} onChange={(selected) => {
                      this.setState({
                        market: selected,
                        ticker: null,
                      }, () => {
                        this.populateTickerOptions(this.props.tickers);
                      })
                    }} />
                  </Grid>
                  <Grid item>
                    <div><b><small>Ticker</small></b></div>
                    <SingleSelect options={this.state.tickerOptions} isSearchable={true} selected={this.state.ticker} minWidth={'160px'} onChange={(selected) => {
                      this.setState({
                        ticker: selected
                      }, () => {
                        this.props.onChange(this.state);
                      })
                    }} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion square expanded={this.state.expanded === 'date'} onChange={this.handleExpanded('date')}>
              <AccordionSummary>
                <Typography>Dates</Typography>
                <span className='ml-auto'>{this.state.expanded === 'date' ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm='auto'>
                    <div><b><small>Start Date</small></b></div>
                    <TextField
                      type="date"
                      variant="outlined"
                      margin="dense"
                      value={this.state.startDateTime}
                      onChange={this.handleChange('startDateTime')}
                      style={{ minWidth: 120 }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm='auto'>
                    <div><b><small>Stop Date</small></b></div>
                    <TextField
                      type="date"
                      variant="outlined"
                      margin="dense"
                      value={this.state.stopDateTime}
                      onChange={this.handleChange('stopDateTime')}
                      style={{ minWidth: 120 }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion square expanded={this.state.expanded === 'entry'} onChange={this.handleExpanded('entry')}>
              <AccordionSummary>
                <Typography>Entries</Typography>
                <span className='ml-auto'>{this.state.expanded === 'entry' ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <div>
                    Entry Signal:
                  </div>
                  <CustomSignalEditor signal={this.state.entrySignal} isRoot={true}
                    onNextOpen={this.state.enterMode === BuySellMode.OnNextOpen} onChange={(signal) => {
                      this.setState({
                        entrySignal: { ...signal }
                      }, () => {
                        this.props.onChange(this.state);
                      })
                    }}></CustomSignalEditor>
                </div>

              </AccordionDetails>
            </Accordion>
            <Accordion square expanded={this.state.expanded === 'exit' || false} onChange={this.handleExpanded('exit')}>
              <AccordionSummary>
                <Typography>Exits</Typography>
                <span className='ml-auto'>{this.state.expanded === 'exit' ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <div>
                      <div>Stop Loss:</div>
                      <div className='ml-3 d-inline-flex w-100'>
                        <div className='my-auto' style={{ width: 100 }}>Fixed Dollar: </div>
                        <div className='my-auto'>
                          <MultiToggle
                            options={onOffOptions}
                            selectedOption={this.state.slFixedOn}
                            onSelectOption={value => {
                              this.handleChangeSwitch('slFixedOn', value)
                            }}
                          />
                        </div>
                        <TextField
                          value={this.state.stopLossFixed}
                          onChange={this.handleChangeNumber('stopLossFixed')}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          disabled={this.state.slFixedOn === 0}
                          variant="outlined"
                          margin="dense"
                          style={{ width: 120 }}
                        />
                      </div>
                      <div className='ml-3 d-inline-flex w-100'>
                        <div className='my-auto' style={{ width: 100 }}>Fixed Percent: </div>
                        <div className='my-auto'>
                          <MultiToggle
                            options={onOffOptions}
                            selectedOption={this.state.slPercentOn}
                            onSelectOption={value => {
                              this.handleChangeSwitch('slPercentOn', value)
                            }}
                          />
                        </div>
                        <TextField
                          value={this.state.stopLossPercent}
                          onChange={this.handleChangeNumber('stopLossPercent')}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 }
                          }}
                          disabled={this.state.slPercentOn === 0}
                          variant="outlined"
                          margin="dense"
                          style={{ width: 120 }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className='d-inline-flex w-100'>
                      <div className='my-auto' style={{ width: 124 }}>Max Hold Time: </div>
                      <div className='my-auto'>
                        <MultiToggle
                          options={onOffOptions}
                          selectedOption={this.state.maxHoldOn}
                          onSelectOption={value => {
                            this.handleChangeSwitch('maxHoldOn', value)
                          }}
                        />
                      </div>
                      <TextField
                        value={this.state.maxHoldTime}
                        onChange={this.handleChangeNumber('maxHoldTime')}
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                        disabled={this.state.maxHoldOn === 0}
                        variant="outlined"
                        margin="dense"
                        style={{ width: 120 }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <div>
                        Exit Signal:
                      </div>
                      <CustomSignalEditor signal={this.state.exitSignal} isRoot={true}
                        onNextOpen={this.state.exitMode === BuySellMode.OnNextOpen}
                        onChange={(signal) => {
                          this.setState({
                            exitSignal: { ...signal }
                          }, () => {
                            this.props.onChange(this.state);
                          })
                        }}></CustomSignalEditor>
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion square expanded={this.state.expanded === 'setting'} onChange={this.handleExpanded('setting')}>
              <AccordionSummary>
                <Typography>Settings</Typography>
                <span className='ml-auto'>{this.state.expanded === 'setting' ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} className='d-inline-flex'>
                    <div className='my-auto' style={{ width: 80 }}>Enter On: </div>
                    <div style={{ width: 160 }}>
                      <MultiToggle
                        options={enterOptions}
                        selectedOption={this.state.enterMode}
                        onSelectOption={value => {
                          this.handleChangeSwitch('enterMode', value)
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className='d-inline-flex'>
                      <div className='my-auto' style={{ width: 80 }}>Exit On: </div>
                      <div style={{ width: 160 }}>
                        <MultiToggle
                          options={exitOptions}
                          selectedOption={this.state.exitMode}
                          onSelectOption={value => {
                            this.handleChangeSwitch('exitMode', value)
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} >
                    <div className='d-inline-flex w-100'>
                      <div className='my-auto' style={{ width: 120 }}>Position Sizing: </div>
                      <div style={{ width: 180 }}>
                        <MultiToggle
                          options={sizingOptions}
                          selectedOption={this.state.sizingMode}
                          onSelectOption={value => {
                            this.handleChangeSwitch('sizingMode', value)
                          }}
                        />
                      </div>
                    </div>
                    <div className='ml-3 pt-1 d-inline-flex'>
                      <div style={{ width: 136 }} className='my-auto'>
                        {
                          this.state.sizingMode === SizingMethod.Share ? 'Number of Shares:' : 'Fixed Dollar:'
                        }
                      </div>
                      <TextField
                        value={this.state.sizingMode === SizingMethod.Share ? this.state.sizingShare : this.state.sizingDollar}
                        onChange={this.handleChangeNumber(
                          this.state.sizingMode === SizingMethod.Share ? 'sizingShare' : 'sizingDollar'
                        )}
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                        variant="outlined"
                        margin="dense"
                        style={{ width: 160 }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className='d-inline-flex w-100'>
                      <div className='my-auto' style={{ width: 160 }}>Transaction Costs: </div>
                      <TextField
                        value={this.state.transCost}
                        onChange={this.handleChangeNumber('transCost')}
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                        variant="outlined"
                        margin="dense"
                        style={{ width: 160 }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className='d-inline-flex'>
                      <div className='my-auto' style={{ width: 80 }}>Direction: </div>
                      <div style={{ width: 160 }}>
                        <MultiToggle
                          options={tradeOptions}
                          selectedOption={this.state.tradeMode}
                          onSelectOption={value => {
                            this.handleChangeSwitch('tradeMode', value)
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}


export default TesterSetting;