import { GetJWTTokenString,getRequestSeasonAPI } from "./common"
import { strategy } from "./strategy"

export const TradeMode = { Long: 0, Short: 1 }
export const SizingMethod = { Share: 0, FixedDollar: 1 }
export const BuySellMode = { OnClose: 0, OnNextOpen: 1 }

export const SignalMode = { Rule: 0, And: 1, Or: 2 }
export const RuleCompareMode = { None: 0, Equal: 1, Between: 2, Greater: 3, Less: 4, GreaterEqual: 5, LessEqual: 6 }
export const SignalOperation = { None: 0, Add: 1, Minus: 2, Multiple: 3, Divide: 4 }

export const TradeExitMode = {
    None: 0, StopLoss: 1, MaxHoldTime: 2, Signal: 3, StopDate: 4
}

export function GetBacktesterResult(setting) {
    return new Promise((resolve, reject) => {
        /*
        fetch('/api/aggregatebar?ticker=' + setting.ticker, {
            method: 'GET',
            headers: {
                token: GetJWTTokenString()
            }
        }).then(res => res.json()).then(res => {
            var result = CalculateResult(setting, res);
            resolve(result);
        }).catch(ex => {
            console.log("Backtester result error: ", ex.message)
            resolve(null);
        })
        */
        const cb=(res)=>
        {
            var result = CalculateResult(setting,res['data']);
            resolve(result);
        }
        const cth=()=>
        {
            resolve(null);
        }
        getRequestSeasonAPI("aggregatebar?ticker=" + setting.ticker,cb,cth);
    })
}

const operationString = ' +-*/'

export function TranslateSignal(signal) {
    if (signal.mode !== SignalMode.Rule) return ''
    if (!signal.atoms || signal.atoms.length === 0) return ''
    if (signal.compareMode === RuleCompareMode.None) return ''
    let trans = ''
    let valid = 0;

    signal.atoms.forEach((atom, id) => {
        if (atom.dataKey === null || atom.dataKey.length === 0) return;
        if (valid) {
            trans += ' ' + operationString[atom.op] + ' ';
        }
        valid++;
        if (atom.dataKey === 'number') {
            trans += atom.value
        } else {
            trans += atom.label
            if (atom.lookback > 0) {
                trans += '[' + atom.lookback + ']'
            }
        }
    })

    if (trans.length === 0) return trans;

    if (signal.compareMode === RuleCompareMode.Greater) {
        trans = trans + ' > '
    } else if (signal.compareMode === RuleCompareMode.GreaterEqual) {
        trans = trans + ' >= '
    } else if (signal.compareMode === RuleCompareMode.Equal) {
        trans = trans + ' = '
    } else if (signal.compareMode === RuleCompareMode.LessEqual) {
        trans = trans + ' <= '
    } else if (signal.compareMode === RuleCompareMode.Less) {
        trans = trans + ' < '
    }

    valid = 0;
    signal.rightAtoms && signal.rightAtoms.forEach((atom, id) => {
        if (atom.dataKey === null || atom.dataKey.length === 0) return;
        if (valid) {
            trans += ' ' + operationString[atom.op] + ' ';
        }
        valid++;
        if (atom.dataKey === 'number') {
            trans += atom.value
        } else {
            trans += atom.label
            if (atom.lookback > 0) {
                trans += '[' + atom.lookback + ']'
            }
        }
    })

    return trans;
}

const OnCloseDataKeys = ['exactDay', 'dayOfWeek', 'dayOfMonth', 'weekOfMonth', 'month', 'quarter'];

function IsTrueSignal(bars, signal, index, onClose) {
    if (signal.mode === SignalMode.And) {
        let result = true;
        if (!signal.children || signal.children.length === 0) {
            return false;
        }
        signal.children && signal.children.forEach(child => {
            if (result) {
                result = result && IsTrueSignal(bars, child, index, onClose);
            }
        })
        return result;
    } else if (signal.mode === SignalMode.Or) {
        if (signal.children === null || signal.children.length === 0) return true;
        if (!signal.children || signal.children.length === 0) {
            return false;
        }
        let result = false;
        signal.children.forEach(child => {
            if (!result) {
                result = result || IsTrueSignal(bars, child, index, onClose);
            }
        })
        return result;
    }

    if (signal.atoms === null || signal.atoms.length === 0) return false;

    //Check Expression
    let valid = true;
    let expression = ''
    signal.atoms.forEach((atom, id) => {
        if (id > 0) {
            expression = expression + operationString[atom.op];
        }
        if (atom.dataKey === 'number') {
            expression = expression + (`(${atom.value})`)
        } else {
            let lookback = atom.lookback
            if (onClose && OnCloseDataKeys.includes(atom.dataKey)) lookback++;
            if (index < lookback) valid = false;
            else {
                if (bars[index - lookback][atom.dataKey] === null) valid = false;
                else expression = expression + (`(${bars[index - lookback][atom.dataKey]})`)
            }
        }
    });

    let right_expression = ''
    signal.rightAtoms.forEach((atom, id) => {
        if (id > 0) {
            right_expression = right_expression + operationString[atom.op];
        }
        if (atom.dataKey === 'number') {
            right_expression = right_expression + (`(${atom.value})`)
        } else {
            let lookback = atom.lookback
            if (onClose && OnCloseDataKeys.includes(atom.dataKey)) lookback++;
            if (index < lookback) valid = false;
            else {
                if (bars[index - lookback][atom.dataKey] === null) valid = false;
                else right_expression = right_expression + (`(${bars[index - lookback][atom.dataKey]})`)
            }
        }
    });

    if (valid === false) return false;

    let expResult = eval(expression);
    let rightResult = eval(right_expression);

    if (signal.compareMode === RuleCompareMode.Greater) {
        return (expResult > rightResult)
    } else if (signal.compareMode === RuleCompareMode.GreaterEqual) {
        return (expResult >= rightResult)
    } else if (signal.compareMode === RuleCompareMode.Equal) {
        return (expResult === rightResult)
    } else if (signal.compareMode === RuleCompareMode.LessEqual) {
        return (expResult <= rightResult)
    } else if (signal.compareMode === RuleCompareMode.Less) {
        return (expResult < rightResult)
    }

    return false;
}

function GetChartData(vals) {
    var result = {};

    if (!vals) return result;

    let values = null, valuesByWeek = null, valuesByMonth = null, curValue = 0;

    values = [0];
    valuesByMonth = {};
    valuesByWeek = {};

    for (var id = 0; id < vals.length; id++) {
        let betResult = vals[id].profit;
        let date = vals[id].entry_date;

        curValue += betResult;

        let year = Math.floor(date / 10000);
        let month = Math.floor((date % 10000) / 100) - 1;
        let day = Math.floor(date % 100)
        let dateobj = new Date(year, month, day, 12, 0, 0);
        let week = dateobj.getDay()
        month = month + 1
        if (valuesByMonth[month] === undefined)
            valuesByMonth[month] = betResult;
        else
            valuesByMonth[month] = valuesByMonth[month] + betResult;
        if (valuesByWeek[week] === undefined)
            valuesByWeek[week] = betResult;
        else
            valuesByWeek[week] = valuesByWeek[week] + betResult;
        values.push(curValue);
    }

    result.values = values;
    result.valuesByMonth = valuesByMonth;
    result.valuesByWeek = valuesByWeek;

    return result;
}


function CalculateResult(conf, bars) {
    /// Conversion
    bars.forEach(bar => {
        if (!bar.conversion) {
            bar.conversion = 1.0;
        }
    })

    let result = {
        results: [],
        bars: bars,
        setting: conf
    };

    if (conf.startDate > conf.stopDate) return result;

    let isLong = conf.tradeMode === TradeMode.Long
    let current_trade = null;
    let bars_in_trade = 0;

    let i_prev;
    let i = 0;

    let detail = new strategy();

    let perfectProfitValue = 0;

    while (i < bars.length) {
        i_prev = i - 1;

        //Close on Prev Close
        if (current_trade !== null && conf.exitMode === BuySellMode.OnClose) {
            let shouldExit = false;
            let exitMode = TradeExitMode.None;

            if (conf.maxHoldOn && bars_in_trade >= conf.maxHoldTime) {
                shouldExit = true;
                exitMode = TradeExitMode.MaxHoldTime
            }

            if (bars[i].date > conf.stopDate) {
                shouldExit = true;
                exitMode = TradeExitMode.StopDate
            }

            let exit_px = conf.exitMode === BuySellMode.OnClose ? bars[i_prev].c : bars[i].o;
            let conv = bars[i_prev].conversion

            let sl_amount = isLong ? current_trade.amount * (current_trade.hh - exit_px) : current_trade.amount * (exit_px - current_trade.ll);
            sl_amount = sl_amount * conv;
            let sl_price = isLong ? current_trade.hh * (100 - conf.stopLossPercent) / 100 : current_trade.ll * (100 + conf.stopLossPercent) / 100;

            if (shouldExit === false && conf.slFixedOn && conf.stopLossFixed > 0 && sl_amount > conf.stopLossFixed) {
                shouldExit = true;
                exitMode = TradeExitMode.StopLoss;
            }

            if (conf.slPercentOn) {
                if (conf.tradeMode === TradeMode.Long) {
                    if (shouldExit === false && conf.stopLossPercent > 0 && exit_px < sl_price) {
                        shouldExit = true;
                        exitMode = TradeExitMode.StopLoss;
                    }
                } else if (conf.tradeMode === TradeMode.Short) {
                    if (shouldExit === false && conf.stopLossPercent > 0 && exit_px > sl_price) {
                        shouldExit = true;
                        exitMode = TradeExitMode.StopLoss;
                    }
                }
            }

            if (shouldExit === false && IsTrueSignal(bars, conf.exitSignal, i, conf.exitMode === BuySellMode.OnClose)) {
                shouldExit = true;
                exitMode = TradeExitMode.Signal
            }

            if (shouldExit) {
                current_trade.exit_date = conf.exitMode === BuySellMode.OnClose ? bars[i_prev].date : bars[i].date;
                current_trade.conversion = conf.exitMode === BuySellMode.OnClose ? bars[i_prev].conversion : bars[i].conversion;
                current_trade.exit_px = exit_px;
                current_trade.profit = isLong ? current_trade.amount * (exit_px - current_trade.entry_px) * current_trade.conversion - conf.transCost :
                    current_trade.amount * (current_trade.entry_px - exit_px) * current_trade.conversion - conf.transCost;
                current_trade.exitMode = exitMode

                result.results.push(current_trade);
                detail.update(current_trade.profit);

                current_trade = null;
                bars_in_trade = 0;
            }
        }

        //Open on Prev Close
        if (current_trade === null && (i_prev >= 0 && bars[i_prev].date >= conf.startDate && conf.enterMode === BuySellMode.OnClose)) {
            if (IsTrueSignal(bars, conf.entrySignal, i, conf.enterMode === BuySellMode.OnClose)) {
                let entry_px = conf.enterMode === BuySellMode.OnClose ? bars[i_prev].c : bars[i].o;
                let entry_conversion = bars[i_prev].conversion;
                let amount = conf.sizingMode === SizingMethod.Share ? conf.sizingShare : conf.sizingDollar / entry_px / entry_conversion;
                let entry_dollar = conf.sizingMode === SizingMethod.Share ? amount * entry_px * entry_conversion : conf.sizingDollar;

                if (amount > 0 && entry_dollar > 0) {
                    current_trade = {
                        entry_date: conf.enterMode === BuySellMode.OnClose ? bars[i_prev].date : bars[i].date,
                        entry_px: entry_px,
                        hh: entry_px,
                        ll: entry_px,
                        amount: amount,
                        entry_dollar: entry_dollar
                    }

                    if (current_trade.entry_date > conf.stopDate) {
                        current_trade = null;
                    }
                }
            }
        }

        //Close on this Open
        if (current_trade !== null/* && conf.exitMode === BuySellMode.OnNextOpen*/) {
            let shouldExit = false;
            let exitMode = TradeExitMode.None;

            if (conf.maxHoldOn && bars_in_trade >= conf.maxHoldTime && conf.exitMode === BuySellMode.OnNextOpen) {
                shouldExit = true;
                exitMode = TradeExitMode.MaxHoldTime
            }

            let closeMode = BuySellMode.OnNextOpen
            if (bars[i].date > conf.stopDate && closeMode === BuySellMode.OnNextOpen) {
                shouldExit = true;
                exitMode = TradeExitMode.StopDate
                closeMode = BuySellMode.OnClose
            }

            let exit_px = bars[i].o;
            let conv = bars[i_prev].conversion;

            let sl_amount = isLong ? current_trade.amount * (current_trade.hh - exit_px) : current_trade.amount * (exit_px - current_trade.ll);
            sl_amount = sl_amount * conv;

            let sl_price = isLong ? current_trade.hh * (100 - conf.stopLossPercent) / 100 : current_trade.ll * (100 + conf.stopLossPercent) / 100;

            if (conf.slFixedOn && shouldExit === false && conf.stopLossFixed > 0 && sl_amount > conf.stopLossFixed) {
                shouldExit = true;
                exitMode = TradeExitMode.StopLoss;
            }

            if (conf.slPercentOn) {
                if (conf.tradeMode === TradeMode.Long) {
                    if (shouldExit === false && conf.stopLossPercent > 0 && exit_px < sl_price) {
                        shouldExit = true;
                        exitMode = TradeExitMode.StopLoss;
                    }
                } else if (conf.tradeMode === TradeMode.Short) {
                    if (shouldExit === false && conf.stopLossPercent > 0 && exit_px > sl_price) {
                        shouldExit = true;
                        exitMode = TradeExitMode.StopLoss;
                    }
                }
            }

            if (shouldExit === false && conf.exitMode === BuySellMode.OnNextOpen && IsTrueSignal(bars, conf.exitSignal, i, conf.exitMode === BuySellMode.OnClose)) {
                shouldExit = true;
                exitMode = TradeExitMode.Signal
            }

            if (shouldExit) {
                current_trade.exit_date = closeMode === BuySellMode.OnClose ? bars[i_prev].date : bars[i].date;
                current_trade.conversion = closeMode === BuySellMode.OnClose ? bars[i_prev].conversion : bars[i].conversion;
                current_trade.exit_px = exit_px;
                current_trade.profit = isLong ? current_trade.amount * (exit_px - current_trade.entry_px) * current_trade.conversion - conf.transCost :
                    current_trade.amount * (current_trade.entry_px - exit_px) * current_trade.conversion - conf.transCost;
                current_trade.exitMode = exitMode

                result.results.push(current_trade);
                detail.update(current_trade.profit);

                current_trade = null;
                bars_in_trade = 0;
            } else {
                //Update HH, LL to current Open
                /*
                if (current_trade.hh < exit_px) {
                    current_trade.hh = exit_px;
                }
                if (current_trade.ll > exit_px) {
                    current_trade.ll = exit_px;
                }
                */
            }
        }

        //Open on Next Open
        if (current_trade === null && (bars[i].date >= conf.startDate && conf.enterMode === BuySellMode.OnNextOpen)) {
            if (IsTrueSignal(bars, conf.entrySignal, i, conf.enterMode === BuySellMode.OnClose)) {
                let entry_px = conf.enterMode === BuySellMode.OnClose ? bars[i_prev].c : bars[i].o;
                let entry_conversion = bars[i_prev].conversion;
                let amount = conf.sizingMode === SizingMethod.Share ? conf.sizingShare : conf.sizingDollar / entry_px / entry_conversion;
                let entry_dollar = conf.sizingMode === SizingMethod.Share ? amount * entry_px * entry_conversion : conf.sizingDollar;

                if (amount > 0 && entry_dollar > 0) {
                    current_trade = {
                        entry_date: conf.enterMode === BuySellMode.OnClose ? bars[i_prev].date : bars[i].date,
                        entry_px: entry_px,
                        hh: entry_px,
                        ll: entry_px,
                        amount: amount,
                        entry_dollar: entry_dollar
                    }

                    if (current_trade.entry_date > conf.stopDate) {
                        current_trade = null;
                    }
                }
            }
        }

        if (bars[i].date > conf.stopDate) break;

        // Perfect Profit
        {
            let pp_entry = conf.enterMode === BuySellMode.OnNextOpen ? bars[i].o : (i_prev >= 0 ? bars[i_prev].c : 0);
            let pp_exit, ps = 0, conv = 1;
            if (conf.exitMode === BuySellMode.OnNextOpen && i < bars.length - 1 && bars[i + 1].date <= conf.stopDate) { //next open
                pp_exit = bars[i + 1].o;
                conv = bars[i + 1].conversion;
            }
            else {
                pp_exit = bars[i].c;
                conv = bars[i].conversion;
            }

            if (pp_entry > 0 && pp_exit > 0) {
                ps = conf.sizingMode === SizingMethod.Share ? conf.sizingShare : conf.sizingDollar / pp_entry

                if (isLong && pp_exit > pp_entry) {
                    perfectProfitValue += ((pp_exit - pp_entry) * ps * conv);
                }
                if (isLong === false && pp_exit < pp_entry) {
                    perfectProfitValue += ((pp_entry - pp_exit) * ps * conv);
                }
            }
        }

        //IntraDay HH, LL Update
        if (current_trade !== null) {
            bars_in_trade++;

            //Exit on Low (Long) or High (Short)

            let shouldExit = false;
            let exitMode = TradeExitMode.None;

            let LL = current_trade.ll;
            let HH = current_trade.hh;

            let exit_px;

            if (isLong) {
                if (bars[i].o - bars[i].l <= bars[i].h - bars[i].o) {
                    //Low Happended first
                    exit_px = bars[i].l;
                } else {
                    //HH = Math.max(HH, bars[i].h);
                    exit_px = bars[i].l;
                }
            } else {
                if (bars[i].o - bars[i].l <= bars[i].h - bars[i].o) {
                    //Low Happended first
                    //LL = Math.min(LL, bars[i].l)
                    exit_px = bars[i].h;
                } else {
                    exit_px = bars[i].h;
                }
            }

            let sl_price = -100, sl_price1 = -100;
            let conv = bars[i].conversion;

            if (conf.slFixedOn && conf.stopLossFixed > 0) {
                sl_price = isLong ? HH - conf.stopLossFixed / current_trade.amount / conv : LL + conf.stopLossFixed / current_trade.amount / conv;
            }
            if (conf.slPercentOn && conf.stopLossPercent > 0) {
                sl_price1 = isLong ? HH * (100 - conf.stopLossPercent) / 100 : LL * (100 + conf.stopLossPercent) / 100;
            }
            if (sl_price === -100) sl_price = sl_price1;
            else if (sl_price1 !== -100) {
                if (isLong && sl_price1 > sl_price) sl_price = sl_price1;
                else if (!isLong && sl_price1 < sl_price) sl_price = sl_price1;
            }

            if (isLong) {
                sl_price = Math.floor(sl_price * 10000) / 10000;
            } else {
                sl_price = Math.ceil(sl_price * 10000) / 10000;
            }

            if (sl_price > -99) {
                if (conf.tradeMode === TradeMode.Long) {
                    if (shouldExit === false && exit_px <= sl_price) {
                        shouldExit = true;
                        exitMode = TradeExitMode.StopLoss;
                        exit_px = sl_price
                    }
                } else if (conf.tradeMode === TradeMode.Short) {
                    if (shouldExit === false && exit_px >= sl_price) {
                        shouldExit = true;
                        exitMode = TradeExitMode.StopLoss;
                        exit_px = sl_price
                    }
                }
            }

            if (shouldExit) {
                current_trade.exit_date = bars[i].date;
                current_trade.conversion = bars[i].conversion
                current_trade.exit_px = exit_px;
                current_trade.profit = isLong ? current_trade.amount * (exit_px - current_trade.entry_px) * current_trade.conversion - conf.transCost :
                    current_trade.amount * (current_trade.entry_px - exit_px) * current_trade.conversion - conf.transCost;
                current_trade.exitMode = exitMode

                result.results.push(current_trade);
                detail.update(current_trade.profit);

                current_trade = null;
                bars_in_trade = 0;
            }

            /*
            if (current_trade) {
                if (!current_trade.hh || current_trade.hh < bars[i].h) {
                    current_trade.hh = bars[i].h;
                }

                if (!current_trade.ll || current_trade.ll > bars[i].l) {
                    current_trade.ll = bars[i].l;
                }
            }
            */
        }
        i++;
    }

    i_prev = i - 1;
    if (i_prev >= 0 && current_trade !== null) {
        let exit_px = bars[i_prev].c;
        current_trade.exit_date = bars[i_prev].date;
        current_trade.conversion = bars[i_prev].conversion;
        current_trade.exit_px = exit_px;
        current_trade.profit = isLong ? current_trade.amount * (exit_px - current_trade.entry_px) * current_trade.conversion - conf.transCost :
            current_trade.amount * (current_trade.entry_px - exit_px) * current_trade.conversion - conf.transCost;
        current_trade.exitMode = TradeExitMode.StopDate

        result.results.push(current_trade);
        detail.update(current_trade.profit);

        current_trade = null;
        bars_in_trade = 0;
    }

    result.allChartData = GetChartData(result.results)
    result.perfectProfitPercentage = perfectProfitValue != 0 ? detail.net_pnl / perfectProfitValue * 100.0 : 0;

    let statData = detail.dumpresults();

    return { ...result, ...statData };
}
