import React from "react";
import { Paper } from "@material-ui/core";
const checkOkValue=(value)=>
{
    if (value == undefined)
        return "undefined";
    if (value == NaN)
        return "NaN";
    return value.toFixed(2);
    
}
const StatsTable = ({ result }) => {
    return (
        <Paper className='p-2'>
            <table className='w-100'>
                <tbody>
                    <tr>
                        <td style={{ width: '65%' }}>Total P&L</td>
                        <td>{result.net_pnl && result.net_pnl.toFixed(2)}</td>
                    </tr>
                    <tr >
                        <td style={{ width: '65%' }}>Drawdown</td>
                        <td>{result.drawdown && result.drawdown.toFixed(2)}</td>
                    </tr>
                    <tr >
                        <td style={{ width: '65%' }}>PNL/DD</td>
                        <td>{checkOkValue(result.pnldd)}</td>
                    </tr>
                    <tr >
                        <td style={{ width: '65%' }}>Trades</td>
                        <td>{result.trades}</td>
                    </tr>
                    <tr >
                        <td style={{ width: '65%' }}>Win %</td>
                        <td>{result.winPercentage && (result.winPercentage * 100).toFixed(2)}</td>
                    </tr>
                    <tr >
                        <td style={{ width: '65%' }}>Profit Factor</td>
                        <td>{result.profitFactor && result.profitFactor.toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </Paper>
    );
};

export default StatsTable;
