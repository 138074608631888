import React from "react";
import Plot from 'react-plotly.js';
import { Paper } from "@material-ui/core";
import { totalchartconfig, getChartColorfulData, totalchartlayout } from "../../logic/chartutils";

const TotalChart = (props) => {
    let chartdata = getChartColorfulData(props.data || [], true)
    let layout = { ...totalchartlayout }
    let title = { ...totalchartlayout.title }
    title.text = "Total Profit/Loss"
    layout.title = title;
    return (
        <Paper className='p-2'>
            <div style={{ width: "100%", height: props.height }}>
                <Plot
                    data={chartdata}
                    layout={layout}
                    config={totalchartconfig}
                    style={{ width: "100%", height: "100%" }}
                    useResizeHandler={true}
                />
            </div>
        </Paper>
    );
};

export default TotalChart;
