import { percentile, sum, std, mean, max_element, min_element } from './common'

const DBL_MAX = 1000000000;

export class strategy {
	constructor(props) {
		this.net_pnl = 0;
		this.max_pnl = 0;
		this.drawdown = 0;
		this.w_trades = [];
		this.l_trades = [];
		this.daily_pnls = [];
		this.results = [];
	}

	update(trade) {
		if (trade > 0.0) {
			this.w_trades.push(trade);
		}
		else {
			this.l_trades.push(-trade);
		}
		this.results.push(trade);
		this.net_pnl += trade;
		if (this.net_pnl > this.max_pnl)
			this.max_pnl = this.net_pnl;
		else if (this.max_pnl - this.net_pnl > this.drawdown)
			this.drawdown = this.max_pnl - this.net_pnl;
	}

	trades() { return this.results.length; }

	winPercentage() {
		return this.trades() === 0 ? 0.0 : (this.w_trades.length / this.trades());
	}

	avgTrade() {
		if (this.trades() === 0) { return 0.0; }
		return (sum(this.w_trades) - sum(this.l_trades)) / this.trades();
	}

	SQN() {
		if (this.results.length <= 0) return -DBL_MAX;

		if (this.results) {
			let s = std(this.results);
			if (s === 0) return -DBL_MAX;
			return Math.pow(this.results.length, .5) * (mean(this.results) / s);
		} else {
			let my_trds = [];
			for (var i = 0; i < this.results.length; ++i)
				my_trds.push(this.results[i].px);
			let s = std(my_trds);
			if (s === 0) return -DBL_MAX;
			return Math.pow(this.results.length, .5) * (mean(my_trds) / s);
		}
	}

	profitFactor() {
		let w_amt = sum(this.w_trades);
		let l_amt = sum(this.l_trades);
		return l_amt === 0 ? 0.0 : w_amt / l_amt;
	}

	ratioWL() {
		return mean(this.l_trades) === 0 ? 0.0 : mean(this.w_trades) / mean(this.l_trades);
	}

	cpcRatio() { return this.ratioWL() * this.winPercentage() * this.profitFactor(); }

	sharpe() {
		let v = std(this.daily_pnls);
		if (v === 0) return -DBL_MAX;
		return mean(this.daily_pnls) / v * Math.sqrt(252.0);
	}

	dumpresults() {
		let res = {};

		res.net_pnl = this.net_pnl;
		res.drawdown = this.drawdown;
		res.pnldd = this.net_pnl / this.drawdown;
		res.winPercentage = this.winPercentage();
		res.profitFactor = this.profitFactor();
		res.trades = this.trades();
		res.avgTrade = this.avgTrade();
		res.ratioWL = this.ratioWL();
		res.cpcRatio = this.cpcRatio();
		this.daily_pnls = this.results;
		res.sharpe = this.sharpe();
		res.SQN = this.SQN();

		return res;
	}

	delete() {
		if (this.results) {
			this.results.length = 0;
		}
		if (this.w_trades) {
			this.w_trades.length = 0;
		}
		if (this.l_trades) {
			this.l_trades.length = 0;
		}
	}
}
