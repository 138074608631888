import { TradeMode, BuySellMode } from "./Backtester";
import { DateStringToInt } from "./DateUtil";

export const candleChartOptions = {
    layout: {
        backgroundColor: 'transparent',
    },
    alignLabels: true,
    timeScale: {
        borderVisible: true,
        visible: true,
        timeVisible: false,
        secondsVisible: false
    },
}

export const timeCandleChartOptions = {
    layout: {
        backgroundColor: 'transparent',
    },
    alignLabels: true,
    timeScale: {
        borderVisible: true,
        visible: true,
        timeVisible: true,
        secondsVisible: false
    },
}

export let tablechartlayout = {
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    height: 30,
    width: 40,
    margin: { pad: 0, t: 0, l: 0, r: 0, b: 0 },
    xaxis: {
        showgrid: false,
        fixedrange: true,
        zeroline: false,
        showline: false,
        mirror: 'ticks',
    },
    yaxis: {
        showgrid: false,
        fixedrange: true,
        zeroline: false,
        showline: false,
        mirror: 'ticks',
    },
    modebar: {
        bgcolor: 'transparent'
    },
    hovermode: false,
    showlegend: false,
    autosize: true,
}

export let tablechartconfig = {
    responsive: false,
    displaylogo: false,
    scrollZoom: false,
    staticPlot: true,
    displayModeBar: false,
    modeBarButtonsToRemove: ['lasso2d', 'select2d', 'zoom2d', 'pan2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines',
        'hoverClosestGeo', 'hoverClosestGl2d', 'hoverClosestPie'],
}

export var gridchartlayout = {
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    height: 200,
    margin: { pad: 0, t: 10, l: 30, r: 10, b: 25 },
    xaxis: {
        showgrid: true,
        fixedrange: true,
        zeroline: true,
        showline: false,
        mirror: 'ticks',
        gridcolor: '#e0e0e0',
        gridwidth: 0,
        zerolinecolor: '#707070',
        zerolinewidth: 1,
        tickfont: {
            color: "#707070",
            size: 10
        }
    },
    yaxis: {
        showgrid: true,
        showline: false,
        zeroline: false,
        fixedrange: true,
        mirror: 'ticks',
        gridcolor: '#e0e0e0',
        gridwidth: 1,
        hoverformat: '.2f',
        tickfont: {
            color: "#707070",
            size: 10
        }
    },
    modebar: {
        bgcolor: 'transparent'
    },
    showlegend: false,
    autosize: true,
}

export var gridchartconfig = {
    responsive: false,
    displaylogo: false,
    scrollZoom: false,
    displayModeBar: false,
    modeBarButtonsToRemove: ['lasso2d', 'select2d', 'zoom2d', 'pan2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines',
        'hoverClosestGeo', 'hoverClosestGl2d', 'hoverClosestPie'],
}

export var candlechartlayout = {
    dragmode: 'zoom',
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    margin: {
        r: 10,
        t: 25,
        b: 40,
        l: 60
    },
    showlegend: false,
    autosize: true,
    xaxis: {
        autorange: true,
        domain: [0, 1],
        range: ['2017-01-03 12:00', '2017-02-15 12:00'],
        rangeslider: { range: ['2017-01-03 12:00', '2017-02-15 12:00'] },
        title: 'Date',
        type: 'date'
    },
    yaxis: {
        autorange: true,
        domain: [0, 1],
        range: [114.609999778, 137.410004222],
        type: 'linear'
    }
};
export var candlechartconfig = {
    responsive: true,
    connectgaps: true,
    displayModeBar: false,
    displaylogo: false,
    modeBarButtonsToRemove: ['lasso2d', 'select2d', 'zoom2d', 'pan2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines',
        'hoverClosestGeo', 'hoverClosestGl2d', 'hoverClosestPie'],
}
export var totalchartlayout = {
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    xaxis: {
        showgrid: true,
        nticks: 10,
        zeroline: true,
        mirror: 'ticks',
        gridcolor: '#e0e0e0',
        gridwidth: 0,
        zerolinecolor: '#707070',
        zerolinewidth: 1,
        tickfont: {
            color: "#707070",
            size: 10
        }
    },
    yaxis: {
        showgrid: true,
        mirror: 'ticks',
        gridcolor: '#e0e0e0',
        gridwidth: 0,
        hoverformat: '.2f',
        tickfont: {
            color: "#707070",
            size: 10
        }
    },
    modebar: {
        bgcolor: 'transparent',
        color: '#a0a0a0',
        activecolor: '#707070'
    },
    autosize: true,
    title: {
        font: {
            color: '#707070',
            size: 15
        },
        x: 0.01,
        xanchor: 'left'
    },
    margin: { pad: 0, t: 30, l: 30, r: 20, b: 25 }
}

export var barchartlayout = {
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    xaxis: {
        showgrid: true,
        nticks: 10,
        autotick: false,
        zeroline: true,
        mirror: 'ticks',
        gridcolor: '#e0e0e0',
        gridwidth: 0,
        zerolinecolor: '#707070',
        zerolinewidth: 1,
        tickfont: {
            color: "#707070",
            size: 10
        }
    },
    yaxis: {
        showgrid: true,
        mirror: 'ticks',
        gridcolor: '#e0e0e0',
        gridwidth: 0,
        hoverformat: '.2f',
        tickfont: {
            color: "#707070",
            size: 10
        }
    },
    modebar: {
        bgcolor: 'transparent',
        color: '#a0a0a0',
        activecolor: '#707070'
    },
    autosize: true,
    title: {
        font: {
            color: '#707070',
            size: 15
        },
        x: 0.01,
        xanchor: 'left'
    },
    margin: { pad: 0, t: 30, l: 30, r: 20, b: 25 }
}


export var totalchartconfig = {
    responsive: true,
    displayModeBar: false,
    displaylogo: false,
    modeBarButtonsToRemove: ['lasso2d', 'select2d', 'zoom2d', 'pan2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines',
        'hoverClosestGeo', 'hoverClosestGl2d', 'hoverClosestPie'],
}

export function getTableChartColorfulData(result, isSum = false) {
    let length = result.length;
    let vals = null;

    if (isSum) {
        vals = result;
    } else {
        vals = [0];
        for (var id = 0; id < length; id++) {
            vals.push(vals[id] + result[id])
        }
    }

    let values = null;
    if (length > 20) {
        values = [];
        var i = 0;
        let step = Math.floor(length / 20);
        for (i = 0; i < length; i += step) {
            values.push(vals[i]);
        }
        if (i - step !== length - 1)
            values.push(vals[length - 1]);
    } else {
        values = vals;
    }

    vals = null;

    let positive = 1;
    let greencolor = '#26a69a'
    var xs = [], ys = [];
    var data = [];

    length = values.length;

    for (var i = 0; i < length; i++) {
        if (positive === 1) {
            if (values[i] >= 0) {
                xs.push(i);
                ys.push(values[i])
            } else {
                let zx = -1;
                if (i > 0) {
                    zx = i - 1 - values[i - 1] / (values[i] - values[i - 1]);
                    xs.push(zx);
                    ys.push(0);
                }
                data.push(
                    { type: 'scatter', mode: 'lines', x: xs, y: ys, line: { color: greencolor }, marker: { color: greencolor }, showlegend: false, hoverinfo: 'x+y' },
                );
                positive = -1;
                if (zx !== -1) {
                    xs = [zx];
                    ys = [0];
                }

                xs.push(i);
                ys.push(values[i]);
            }
        } else {
            if (values[i] > 0) {
                let zx = -1;
                if (i > 0) {
                    zx = i - 1 - values[i - 1] / (values[i] - values[i - 1]);
                    xs.push(zx);
                    ys.push(0);
                }
                data.push(
                    { type: 'scatter', mode: 'lines', x: xs, y: ys, line: { color: '#FF7B29' }, marker: { color: '#FF7B29' }, showlegend: false, hoverinfo: 'x+y' },
                );
                positive = 1;
                if (zx !== -1) {
                    xs = [zx];
                    ys = [0];
                }
                xs.push(i);
                ys.push(values[i])
            } else {
                xs.push(i);
                ys.push(values[i]);
            }
        }
    }
    values = null;
    if (positive === 1) {
        data.push(
            { type: 'scatter', mode: 'lines', x: xs, y: ys, line: { color: greencolor }, marker: { color: greencolor }, showlegend: false, hoverinfo: 'x+y' },
        );
    } else {
        data.push(
            { type: 'scatter', mode: 'lines', x: xs, y: ys, line: { color: '#FF7B29' }, marker: { color: '#FF7B29' }, showlegend: false, hoverinfo: 'x+y' },
        );
    }
    xs = ys = null;

    return data;
}

export function getChartColorfulData(result, isSum = false) {
    let length = result.length;

    let values = null;
    if (isSum) {
        values = result;
    } else {
        values = [0];
        for (var id = 0; id < length; id++) {
            values.push(values[id] + result[id])
        }
    }

    let positive = 1;
    let greencolor = '#26a69a'
    var xs = [], ys = [];
    var data = [];
    for (var i = 0; i < length; i++) {
        if (positive === 1) {
            if (values[i] >= 0) {
                xs.push(i);
                ys.push(values[i])
            } else {
                let zx = -1;
                if (i > 0) {
                    zx = i - 1 - values[i - 1] / (values[i] - values[i - 1]);
                    xs.push(zx);
                    ys.push(0);
                }
                data.push(
                    { type: 'scatter', mode: 'lines', x: xs, y: ys, line: { color: greencolor }, marker: { color: greencolor }, showlegend: false, hoverinfo: 'x+y' },
                );
                positive = -1;
                if (zx !== -1) {
                    xs = [zx];
                    ys = [0];
                }

                xs.push(i);
                ys.push(values[i]);
            }
        } else {
            if (values[i] > 0) {
                let zx = -1;
                if (i > 0) {
                    zx = i - 1 - values[i - 1] / (values[i] - values[i - 1]);
                    xs.push(zx);
                    ys.push(0);
                }
                data.push(
                    { type: 'scatter', mode: 'lines', x: xs, y: ys, line: { color: '#ef5350' }, marker: { color: '#ef5350' }, showlegend: false, hoverinfo: 'x+y' },
                );
                positive = 1;
                if (zx !== -1) {
                    xs = [zx];
                    ys = [0];
                }
                xs.push(i);
                ys.push(values[i])
            } else {
                xs.push(i);
                ys.push(values[i]);
            }
        }
    }
    if (positive === 1) {
        data.push(
            { type: 'scatter', mode: 'lines', x: xs, y: ys, line: { color: greencolor }, marker: { color: greencolor }, showlegend: false, hoverinfo: 'x+y' },
        );
    } else {
        data.push(
            { type: 'scatter', mode: 'lines', x: xs, y: ys, line: { color: '#ef5350' }, marker: { color: '#ef5350' }, showlegend: false, hoverinfo: 'x+y' },
        );
    }

    return data;
}

const DateString = (date) => {
    let datestr = '' + date;
    return datestr.substring(0, 4) + '-' + datestr.substring(4, 6) + '-' + datestr.substring(6);
}

export function GetCandleChartData(result) {
    let bars = result.bars;
    let candles = [];
    let markers = [];
    let lineData = [];

    bars.forEach((x, id) => {
        candles.push({
            high: x.h,
            low: x.l,
            close: x.c,
            open: x.o,
            time: DateString(x.date)
        })
    });

    let isLong = result.setting.tradeMode === TradeMode.Long
    result.results.forEach(trade => {
        if (trade.entry_date !== trade.exit_date) {
            lineData.push([
                { time: DateString(trade.entry_date), value: trade.entry_px },
                { time: DateString(trade.exit_date), value: trade.exit_px }
            ])
        }

        markers.push({
            time: DateString(trade.entry_date),
            position: isLong ? 'belowBar' : 'aboveBar',
            color: isLong ? '#26a69a' : '#ef5350',
            shape: isLong ? 'arrowUp' : 'arrowDown',
            text: isLong ? 'Buy' : 'Short'
        })

        markers.push({
            time: DateString(trade.exit_date),
            position: !isLong ? 'belowBar' : 'aboveBar',
            color: !isLong ? '#26a69a' : '#ef5350',
            shape: !isLong ? 'arrowUp' : 'arrowDown',
            text: isLong ? 'Sell' : 'Cover'
        })
    })

    return { candleData: candles, markersData: markers, lineData: lineData }
}

export function CalculateSeasonalChartData(barArray, yearsBack, startDate, endDate, startTDom, endTDom, xOption) {
    if (!barArray || barArray.length === 0) return null;

    let startDateInt = (startDate.getMonth() + 1) * 100 + startDate.getDate()
    let endDateInt = (endDate.getMonth() + 1) * 100 + endDate.getDate();

    let currentDate = new Date();
    let currentYear = currentDate.getFullYear() - yearsBack
    
    let bars = barArray.filter(x => x.date >= currentYear * 10000)
    
    if (!bars || bars.length === 0) return null;

    let candles = [];
    let markers = [];
    let lineData = [];

    bars.forEach((x, id) => {
        candles.push({
            high: x.h,
            low: x.l,
            close: x.c,
            open: x.o,
            time: DateString(x.date)
        })
    });

    let tdom = 0;
    let lastYear = 0;
    let valuesByDate = {}
    let valuesByTDOM = {}
    let startYear = Math.floor(bars[0].date / 10000);
    if (bars[0].exactDay >= 105) { //Jan 5
        startYear = startYear + 1;
    }
    startYear = startYear * 10000;

    let trades = [];
    let tradeYear = 0
    let curTrade = null;

    bars.forEach((bar, id) => {
        let year = Math.floor(bar.date / 10000);

        if (year !== lastYear) {
            tdom = 1;
        } else {
            tdom = tdom + 1;
        }

        if (!xOption) {
            if (year !== tradeYear && bar.exactDay >= startDateInt && bar.exactDay <= endDateInt) {
                tradeYear = year;
                let entryPrice = (id > 0 && lastYear === year) ? bars[id - 1].c : bar.o;
                let entry_date = (id > 0 && lastYear === year) ? bars[id - 1].date : bar.date;
                curTrade = {
                    year: year,
                    entryPrice: entryPrice,
                    entry_date,
                    entry_mode: (id > 0 && lastYear === year) ? BuySellMode.OnClose : BuySellMode.OnNextOpen
                }
            }
            if (curTrade && (bar.exactDay > endDateInt || year !== tradeYear)) {
                let exitPrice = bars[id - 1].c;
                curTrade.exitPrice = exitPrice;
                curTrade.exit_date = bars[id - 1].date;
                trades.push(curTrade);
                curTrade = null;
            }
        } else {
            if (year !== tradeYear && tdom >= startTDom && tdom <= endTDom) {
                tradeYear = year;
                let entryPrice = (id > 0 && lastYear === year) ? bars[id - 1].c : bar.o;
                let entry_date = (id > 0 && lastYear === year) ? bars[id - 1].date : bar.date;
                curTrade = {
                    year: year,
                    entryPrice: entryPrice,
                    entry_date,
                    entry_mode: (id > 0 && lastYear === year) ? BuySellMode.OnClose : BuySellMode.OnNextOpen
                }
            }
            if (curTrade && (tdom > endTDom || year !== tradeYear)) {
                let exitPrice = bars[id - 1].c;
                curTrade.exitPrice = exitPrice;
                curTrade.exit_date = bars[id - 1].date;
                trades.push(curTrade);
                curTrade = null;
            }
        }

        lastYear = year;
        bar.tdom = tdom;

        if (bar.percentChange !== null && bar.percentChange !== undefined) {
            if (!valuesByDate[bar.exactDay]) {
                valuesByDate[bar.exactDay] = [];
            }
            valuesByDate[bar.exactDay].push(bar.percentChange);

            if (bar.date >= startYear) {
                if (!valuesByTDOM[bar.tdom]) {
                    valuesByTDOM[bar.tdom] = []
                }
                valuesByTDOM[bar.tdom].push(bar.percentChange)
            }
        }
    })
    if (curTrade) {
        curTrade.exitPrice = bars[bars.length - 1].c;
        curTrade.exit_date = bars[bars.length - 1].date;
        trades.push(curTrade);
    }

    let chartByDate = [];
    let chartByTDOM = [];

    for (var i = 0; i <= 1299; i++) {
        if (valuesByDate[i]) {
            let sum = 0;
            valuesByDate[i].forEach(x => sum += x);

            let month = Math.floor(i / 100);
            let date = i % 100;
            if (month < 10) month = '0' + month;
            else month = '' + month;
            if (date < 10) date = '0' + date;
            else date = '' + date;

            chartByDate.push({
                index: i,
                x: i,
                time: '2020-' + month + '-' + date,
                value: sum / valuesByDate[i].length
            })
        }
        if (valuesByTDOM[i]) {
            let sum = 0;
            valuesByTDOM[i].forEach(x => sum += x);
            chartByTDOM.push({
                index: i,
                time: (1800 + i) + '-01-01',
                x: i,
                isTrue: i >= startTDom - 1 && i <= endTDom,
                value: sum / valuesByTDOM[i].length
            })
        }
    }

    chartByDate.sort((x, y) => x.index - y.index);
    chartByTDOM.sort((x, y) => x.index - y.index);

    chartByDate.forEach((data, i) => {
        if ((data.index >= startDateInt || (i + 1 < chartByDate.length && chartByDate[i + 1].index >= startDateInt)) && data.index <= endDateInt) {
            data.isTrue = true;
        } else {
            data.isTrue = false;
        }
    })

    let sum = 100;
    chartByDate.forEach(x => {
        x.value += sum;
        sum = x.value;
    })
    sum = 100;
    chartByTDOM.forEach(x => {
        x.value += sum;
        sum = x.value;
    })

    let totalProfit = 100, avgProfit = 0, sumProfit = 0;

    let win = 0, loss = 0, winProfit = 0, lossProfit = 0, maxWinProfit = 0, maxLossProfit = 0;
    trades.forEach(trade => {
        let chg = (trade.exitPrice - trade.entryPrice) / trade.entryPrice * 100.0;
        trade.changePercent = chg;
        if (chg > 0) {
            win++;
            winProfit += chg;
            if (chg > maxWinProfit) maxWinProfit = chg;
        } else {
            loss++;
            lossProfit += chg;
            if (chg < maxLossProfit) maxLossProfit = chg;
        }
        sumProfit += chg;
        totalProfit = totalProfit * (100 + chg) / 100;


        if (trade.entry_date !== trade.exit_date) {
            lineData.push([
                { time: DateString(trade.entry_date), value: trade.entryPrice },
                { time: DateString(trade.exit_date), value: trade.exitPrice }
            ])
        }


        markers.push({
            time: DateString(trade.entry_date),
            position: 'belowBar',
            color: '#26a69a',
            shape: 'arrowUp',
            text: 'Buy'
        })

        markers.push({
            time: DateString(trade.exit_date),
            position: 'aboveBar',
            color: '#ef5350',
            shape: 'arrowDown',
            text: 'Cover'
        })
    })
    if (trades.length > 0) {
        avgProfit = sumProfit / trades.length;
    }

    totalProfit -= 100;

    return {
        chartByDate,
        chartByTDOM,
        trades,
        totalProfit, avgProfit, win, loss, winProfit, lossProfit, maxWinProfit, maxLossProfit,
        candles, markers, lineData
    }
}