import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import { Modal, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Grid, Button, Container, Paper,TextField,Backdrop, CircularProgress, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';

import { GetJWTTokenString,getRequestSeasonAPI ,postRequestSeasonAPI} from '../../logic/common';


import TesterSetting from './TesterSetting';
import { BuySellMode, GetBacktesterResult, SizingMethod, TradeMode } from '../../logic/Backtester';
import { DateStringToInt } from '../../logic/DateUtil';
import TotalChart from './TotalChart';
import { Fragment } from 'react';
import ChartByWeek from './ChartByWeek';
import ChartByMonth from './ChartByMonth';
import StatsTable from './StatsTable';
import StatsTable1 from './StatsTable1';
import { GetCandleChartData } from '../../logic/chartutils';
import { createChart } from 'lightweight-charts';

import { UpdateUsage, UpdateUsageTime } from '../../logic/counterutil';
import EmailCaptureContainer from '../../Components/EmailCaptureContainer';
import SimpleMsg from "../../Components/SimpleMsg";
import { dataKeyOptions } from './CustomSignalEditor';

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

let initSetting = {
  market: { label: 'Stocks', value: 'stocks' },
  startDateTime: '2008-01-01',
  stopDateTime: formatDate(new Date()),
  entrySignal: null,
  exitSignal: null,
  maxHoldTime: 5,
  transCost: 0,
  stopLossFixed: 0,
  stopLossPercent: 2,
  tradeMode: TradeMode.Long,
  enterMode: BuySellMode.OnClose,
  exitMode: BuySellMode.OnClose,
  sizingMode: SizingMethod.Share,
  sizingDollar: 100,
  slFixedOn: 0,
  slPercentOn: 0,
  maxHoldOn: 1,
  sizingShare: 1
};

const DateString = (date) => {
  let datestr = '' + date;
  return datestr.substring(0, 4) + '-' + datestr.substring(4, 6) + '-' + datestr.substring(6);
}

function ConvertToTypes(values) {
  let result = [];
  if (!values) return result;
  values.forEach(y => {
    let option = dataKeyOptions.find(x => x.value === y)
    if (option) result.push(option.type || option.label)
  })
  return result;
}

function GetSignalType(signal) {
  let result = [];
  if (signal.children) {
    signal.children.forEach(child => {
      result = [...result, ...GetSignalType(child)];
    })
  }

  if (signal.atoms) {
    signal.atoms.forEach(atom => {
      if (atom.dataKey !== 'number') {
        result.push(atom.dataKey);
      }
    })
  }
  if (signal.rightAtoms) {
    signal.rightAtoms.forEach(atom => {
      if (atom.dataKey !== 'number') {
        result.push(atom.dataKey);
      }
    })
  }

  return result;
}

export default function Backtester(props) {
  const { userid } = useParams();

  const [loading, setLoading] = useState(false);  
  const [emailCaptureOpen, setEmailCaptureOpen] = useState(false);
  const [msgboxOpen, setMsgboxOpen] = useState(false);

  const [company, setCompanyInfo] = useState(null);
  const [setting, setSetting] = useState(JSON.parse(localStorage.getItem('tester-setting')) || { ...initSetting })
  const [allTickers, setAllTickers] = useState([])
  const [result, setResult] = useState(null);
  const chartRef = React.useRef();
  const [chartobj, setChartObj] = useState(null);
  const [resizeCounter, setResizeCounter] = useState(0);
  const [accessDenied, setAccessDenied] = useState(false);

  const [annualReturn, setAnnualReturn] = useState(0);
  const [riskFreeRate, setRiskFreeRate] = useState(0);
  const [standardDeviation, setStandardDeviation] = useState(0);
  const [sharpeRatio, setSharpeRatio] = useState(0)



  const onChangeSetting = (newsetting) => {
    let temp = { ...newsetting }
    delete temp.expanded
    delete temp.tickerOptions
    delete temp.tipOpen
    delete temp.tipPage

    localStorage.setItem("tester-setting", JSON.stringify(temp))
    setSetting({ ...temp })
  }

  useEffect(()=>{
    if(standardDeviation==0)
    {
      setSharpeRatio(0.0);
      
    }else{
      var sr = (annualReturn-riskFreeRate)/standardDeviation;
      var srFix = sr.toFixed(2);
      setSharpeRatio(srFix);
    }
  },[annualReturn,riskFreeRate,standardDeviation])
  
  useEffect(() => {
    /*
    if (id && id.length > 0) {
      
    } else {
      
    }
    */
    loadData();
    window.addEventListener("focus", onFocus)
    window.addEventListener("blur", onBlur)
    window.addEventListener("resize", handleResize);
    window.addEventListener("beforeunload", onBeforeUnload)

    //UpdateUsageTime(id, 'backtester', 'tool', 'visit', true, true);

    return function () {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("focus", onFocus)
      window.removeEventListener("blur", onBlur)
      window.removeEventListener("beforeunload", onBeforeUnload)
    }
  }, [])

  const onBeforeUnload = (e) => {
    //UpdateUsageTime(id, 'backtester', 'tool', 'visit', false);
  }

  const onFocus = () => {
    if (!accessDenied) {
      //UpdateUsageTime(id, 'backtester', 'tool', 'visit', true, true);
    }
  }

  const onBlur = () => {
    if (!accessDenied) {
      //UpdateUsageTime(id, 'backtester', 'tool', 'visit', false, true);
    }
  }

  const loadData = () => {
    const cb=(res)=>
    {
      setAllTickers(res['data']);
    }
    getRequestSeasonAPI("tickers",cb);
    
  }

  useEffect(() => {
    if (chartobj) {
      chartobj.applyOptions({
        width: chartRef.current.clientWidth
      })
    }
  }, [resizeCounter])

  const handleResize = () => {
    setResizeCounter(x => x + 1);
  }

  const handleClose = () => {
    setEmailCaptureOpen(false);
  }

  let totalPL = 0;
  const closeMsgbox=()=>
    {
        setMsgboxOpen(false);
    }
    const emailCallback=(res)=>
    {
        setLoading(false);
        setMsgboxOpen(true);
    }
    const onEmailContainerClose=()=>
    {
        setEmailCaptureOpen(false);
        setLoading(true);
    }
  return (
    <Container maxWidth='md'>
      <EmailCaptureContainer open={emailCaptureOpen} onClose={onEmailContainerClose} callback={emailCallback} userid={userid}></EmailCaptureContainer>
      <SimpleMsg open={msgboxOpen} handleClose={closeMsgbox}/>
      <Backdrop open={loading} style={{ zIndex: 1000 }}>
          <CircularProgress style={{ color: 'blue' }} /> <span style={{ color: 'blue' }}>&nbsp;&nbsp;<b>Loading...</b></span>
      </Backdrop>
      {
        accessDenied === false ? (
          <Grid container spacing={2}>
            <Grid item xs={12} className='text-left pt-4'>
              <Typography variant="h4" component="h1" gutterBottom>
                Stock Market Backtester
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TesterSetting tickers={allTickers} setting={setting} onChange={onChangeSetting}></TesterSetting>
            </Grid>
            <Grid item xs={12} className='text-center'>
              <Button color='primary' variant='contained' onClick={async () => {
                setResult(null);
                let testerSetting = { ...setting }
                testerSetting.ticker = setting.ticker.value;
                testerSetting.market = setting.market.value;
                testerSetting.startDate = DateStringToInt(setting.startDateTime)
                testerSetting.stopDate = DateStringToInt(setting.stopDateTime)
                /*
                let counter = UpdateUsage(id, `backtester${setting.tradeMode}`, 'symbol', setting.ticker.value, true)
                UpdateUsage(id, `backtester${setting.tradeMode}`, 'market', setting.market.value, false)
                let entrySignals = ConvertToTypes(GetSignalType(testerSetting.entrySignal));
                UpdateUsage(id, `backtester${setting.tradeMode}`, 'signal', entrySignals, false)
                if (counter > 10) {
                  setEmailCaptureOpen(true);
                }
                */
                let result = await GetBacktesterResult(testerSetting);
                
                let candleData = GetCandleChartData(result)

                if (chartRef.current.firstChild) {
                  chartRef.current.removeChild(chartRef.current.firstChild)
                }
                const chart = createChart(chartRef.current, { width: chartRef.current.clientWidth, height: 300 });
                
                candleData.lineData.forEach(line => {
                  let lineSeries = chart.addLineSeries({
                    priceLineVisible: false,
                    lastValueVisible: false,
                    lineWidth: 1.5,
                    color: 'blue',
                  });
                  lineSeries.setData(line)
                })
                let candles = chart.addCandlestickSeries();
                candles.setData(candleData.candleData)
                candles.setMarkers(candleData.markersData)
                
                setChartObj(chart);
                
                setResult(result);
                //console.log(result);
                const cb=(res)=>{
                  var cnt =  res['data'][0]['result']['cnt'];
                  var email =  res['data'][0]['result']['email'];
                  //console.log(res);
                  if(parseInt(cnt)>5)
                  {                    
                      if(email=='')
                      {
                          setEmailCaptureOpen(true);
                      }            
                  }
                  //console.log(window.parent);
                }
                const data = { userid: userid};
                postRequestSeasonAPI("usecount",data,cb);
              }}>Run Backtester</Button>
            </Grid>
            {
              result &&
              <Fragment>
                <Grid item xs={12} md={5}>
                  <TotalChart height={300} data={result.allChartData.values} />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <StatsTable result={result}></StatsTable>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StatsTable1 result={result}></StatsTable1>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ChartByWeek height={135} data={result.allChartData.valuesByWeek}></ChartByWeek>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ChartByMonth height={135} data={result.allChartData.valuesByMonth}></ChartByMonth>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            }
            {
              <Grid item xs={12}>
                <div ref={chartRef} id='chart-div' className={result ? 'border' : 'border-transparent'}>
                </div>
              </Grid>
            }
            {
              result &&
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table stickyHeader
                    className="compact-table"
                    size='small'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell>Entry Date</TableCell>
                        <TableCell>Exit Date</TableCell>
                        <TableCell>Entry Value</TableCell>
                        <TableCell>Exit Value</TableCell>
                        <TableCell>Result</TableCell>
                        <TableCell>Total P&amp;L</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        result.results.map((row, id) => {
                          totalPL += row.profit;
                          return (
                            <TableRow key={id}>
                              <TableCell>{id + 1}</TableCell>
                              <TableCell>{DateString(row.entry_date)}</TableCell>
                              <TableCell>{DateString(row.exit_date)}</TableCell>
                              <TableCell>{row.entry_px.toFixed(4)}</TableCell>
                              <TableCell>{row.exit_px.toFixed(4)}</TableCell>
                              <TableCell>{row.profit.toFixed(2)}</TableCell>
                              <TableCell>{totalPL.toFixed(2)}</TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            }
          </Grid>
        ) : (
          <div className='text-center pt-5'>
            <div>
              <img src='/img/favicon.png' width={100}></img>
            </div>
            <h3>Access Denied. Please contact support at <a href='mailto:david@axiomalerts.com'>david@axiomalerts.com</a></h3>
          </div>
        )
      }
    </Container>
  );
}