import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useParams } from "react-router-dom";

import {
    TextField, Paper, Backdrop, CircularProgress, Grid, Container, IconButton, Modal,
    Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TablePagination, TableSortLabel, Hidden, Button, Box
} from '@material-ui/core'

import { GetJWTTokenString,getRequestSeasonAPI,getRequestSeasonAPIJSON,validateEmail} from '../logic/common';
import EmailCapture from './EmailCapture';

export default function EmailCaptureContainer(props) {
    const {open,onClose,callback,userid} = props;

    const [email, setEmail] = useState("");
    
    const verifyEmail = () => {
        if (!email || email.length === 0 || !validateEmail(email)) 
            return;         
        props.onClose();        
        //getRequestSeasonAPI("getSymbols",cb);
        getRequestSeasonAPI("sendemail?email="+email+"&userid="+userid,callback); 
    }
    
    const changeEmail=(value)=>
    {
        setEmail(value);
    }
    return (
        <div>
            <Modal  open={open}>
                    <div className='email-modal'>
                        <EmailCapture email={email} onClose={verifyEmail} onChange={changeEmail}/>
                    </div>
            </Modal>            
        </div>
    )
}
